import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { BUCKET } from '@angular/fire/compat/storage';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignupComponent } from './signup/signup.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AuthenticationService } from './shared/services/authentication.service';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NavbarComponent } from './navbar/navbar.component';
import { ReportsListComponent } from './reports-list/reports-list.component';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { MatTableModule } from '@angular/material/table'
import { MatIconModule } from '@angular/material/icon'
import { MatSortModule } from '@angular/material/sort';
import { ReportDetailedComponent } from './report-detailed/report-detailed.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GetVisualizationDataService } from './get-visualization-data.service';
import { MatTabsModule } from '@angular/material/tabs';
import { ReportMultivariateComponent } from './report-multivariate/report-multivariate.component';
import { ReportHealthComponent } from './report-health/report-health.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { NgxLoadingModule } from "ngx-loading";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ReportUnivariateComponent } from './report-univariate/report-univariate.component';
import { FileUploadInstructionsComponent } from './file-upload-instructions/file-upload-instructions.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserHelpComponent } from './user-help/user-help.component';
import { provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { CtaSalesComponent } from './cta-sales/cta-sales.component';
import { CtaSupportComponent } from './cta-support/cta-support.component';
import { CtaContactComponent } from './cta-contact/cta-contact.component';
import { AboutComponent } from './about/about.component';
import { TrialNoticeComponent } from './trial-notice/trial-notice.component';
import { UserPasswordSettingsComponent } from './user-password-settings/user-password-settings.component';
import { UserProfileSettingsComponent } from './user-profile-settings/user-profile-settings.component';
import { LegalLinksComponent } from './legal-links/legal-links.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FaqComponent } from './faq/faq.component';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategyService } from './shared/services/custom-route-reuse-strategy.service';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginNoteComponent } from './login-note/login-note.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { ConfigureBeforeUploadComponent } from './configure-before-upload/configure-before-upload.component';
import { WorkbenchComponent } from './workbench/workbench.component';
import { DataPreviewComponent } from './data-preview/data-preview.component';
import { RuleBuilderComponent } from './rule-builder/rule-builder.component';
import { MatDialogModule } from '@angular/material/dialog';
import { WorkbenchInstructionsComponent } from './workbench-instructions/workbench-instructions.component';
import {MatChipsModule} from '@angular/material/chips';
import { SourceToTargetComponent } from './source-to-target/source-to-target.component';
import { DataPreviewSourceComponent } from './data-preview-source/data-preview-source.component';
import { DataPreviewTargetComponent } from './data-preview-target/data-preview-target.component';
import { DataPreviewMultiService } from './shared/services/data-preview-multi.service';
import { DataPreviewSourceToTargetComponent } from './data-preview-source-to-target/data-preview-source-to-target.component';

@NgModule({
  declarations: [
    AppComponent,
    FileUploadComponent,
    LoginComponent,
    SignupComponent,
    NavbarComponent,
    ReportsListComponent,
    ReportDetailedComponent,
    ReportMultivariateComponent,
    ReportHealthComponent,
    ReportUnivariateComponent,
    FileUploadInstructionsComponent,
    UserProfileComponent,
    UserHelpComponent,
    VerifyEmailComponent,
    CtaSalesComponent,
    CtaSupportComponent,
    CtaContactComponent,
    AboutComponent,
    TrialNoticeComponent,
    UserPasswordSettingsComponent,
    UserProfileSettingsComponent,
    LegalLinksComponent,
    ForgotPasswordComponent,
    FaqComponent,
    LoginFormComponent,
    LoginNoteComponent,
    ConfigurationComponent,
    ConfigureBeforeUploadComponent,
    WorkbenchComponent,
    DataPreviewComponent,
    RuleBuilderComponent,
    WorkbenchInstructionsComponent,
    SourceToTargetComponent,
    DataPreviewSourceComponent,
    DataPreviewTargetComponent,
    DataPreviewSourceToTargetComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideStorage(() => getStorage()),
    provideAuth(() => getAuth()),
    BrowserAnimationsModule,
    MatSlideToggleModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    MatGridListModule,
    MatToolbarModule,
    MatTableModule,
    MatIconModule,
    MatSortModule,
    MatTabsModule,
    MatSliderModule,
    MatPaginatorModule,
    NgxChartsModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatExpansionModule,
    provideFirestore(() => getFirestore()),
    NgxLoadingModule.forRoot({}),
    NgxSkeletonLoaderModule,
    provideAnalytics(() => getAnalytics()),
    MatDialogModule,
    MatListModule,
    MatChipsModule
  ],
  providers: [
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    { provide: BUCKET, useValue: 'anode-beta-inputs' },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategyService },
    AuthenticationService,
    GetVisualizationDataService,
    ScreenTrackingService, UserTrackingService,
    DataPreviewMultiService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
