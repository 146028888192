import { Injectable, NgZone, inject } from '@angular/core';
import { AuthCredential, EmailAuthCredential, GoogleAuthProvider, EmailAuthProvider, reauthenticateWithCredential, updatePassword } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { finalize, last } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FieldValue, serverTimestamp, Timestamp } from '@angular/fire/firestore';
import { SnackbarService } from './snackbar.service';
import { LoaderService } from './loader.service';
import { Router } from "@angular/router";
import { NonNullableFormBuilder } from '@angular/forms';
import { EventsToDbService } from '@app/shared/services/events-to-db.service';



export interface UserProfile {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  designation: string;
  city: string,
  country: string,
  dateCreated: Timestamp | FieldValue;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  private UserProfile: AngularFirestoreCollection<UserProfile>;
  items: Observable<UserProfile[]>;

  appUrl: string;


  constructor(
    private afs: AngularFirestore,
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    private snackbarService: SnackbarService,
    private loaderService: LoaderService,
    private router: Router,
    public ngZone: NgZone,
    public eventsToDbService: EventsToDbService
  ) {
    this.UserProfile = afs.collection<UserProfile>('UserProfile');
    this.items = this.UserProfile.valueChanges();
    this.appUrl = `https://anode.beta.codygon.com/`;
  }


  sendVerificationMail() {
    if(this.afAuth.currentUser) {
      this.afAuth.currentUser
        .then(user => {
          if(user) {
            user.sendEmailVerification(
              {
                url: this.appUrl,
              }
            );
            this.eventsToDbService.addEventToDB("sent_verification_email");
          }
        })
        .then(() => {
          this.router.navigate(['verify-email']);
        });
    }
  }


  // Sign up with email/password
  signup(email, password, firstName, lastName, company, designation, city, country) {

    let _self = this;
    this.loaderService.setLoading(true)

    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        this.sendVerificationMail();
        this.snackbarService.openSnackBar(this.snackbarService.messages.signupSuccess, null, "success");
        result.user.updateProfile({
          displayName: firstName + " " + lastName,
        })
        console.log(result.user);

        let item: UserProfile = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          company: company,
          designation: designation,
          city: city,
          country: country,
          dateCreated: serverTimestamp(),
        }
        this.eventsToDbService.addEventToDB("signup_with_email");
        _self.addItem(item);
        this.router.navigate(['verify-email']);
      })
      .catch((error) => {
        console.error(error.message);
        if (error.message.includes("email-already-in-use")) {
          this.snackbarService.openSnackBar(this.snackbarService.messages.signupEmailAlreadyInUse, null, "error");
        } else {
          this.snackbarService.openSnackBar(this.snackbarService.messages.signupFailure, null, "error");
        }
      })
      .finally(() => { this.loaderService.setLoading(false); });
  }

  // Sign in with email/password
  login(email, password) {


    this.loaderService.setLoading(true);

    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {

        console.log(`result`)
        console.log(result)

        if (!result.user.emailVerified) {
          // this.sendVerificationMail();
          this.snackbarService.openSnackBar(this.snackbarService.messages.validateEmail, null, "error");
        } else {
          this.ngZone.run(() => {
            this.router.navigate(['/']);
          });
          this.eventsToDbService.addEventToDB("login_with_email");
          this.snackbarService.openSnackBar(this.snackbarService.messages.loginSuccess, null, "success");
        }
      })
      .catch((error) => {
        console.error(error.message)
        if (error.message.includes("wrong-password")) {
          this.snackbarService.openSnackBar(this.snackbarService.messages.loginWrongPassword, null, "error");
        }
        else if (error.message.includes("user-not-found")) {
          this.snackbarService.openSnackBar(this.snackbarService.messages.loginUserNotFound, null, "error");
        }
        else if (error.message.includes("invalid-email")) {
          this.snackbarService.openSnackBar(this.snackbarService.messages.loginInvalidEmail, null, "error");
        } else {
          this.snackbarService.openSnackBar(this.snackbarService.messages.loginFailure, null, "error");
        }
      })
      .finally(() => { this.loaderService.setLoading(false); });
  }

  loginWithGoogle() {
    let _self = this;
    this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((result) => {
        console.log(result);
        let item: UserProfile = {
          firstName: result.additionalUserInfo.profile['given_name'],
          lastName: result.additionalUserInfo.profile['family_name'],
          email: result.user.email,
          company: "",
          designation: "",
          city: "",
          country: "",
          dateCreated: serverTimestamp(),
        }
        _self.addItem(item)
        this.eventsToDbService.addEventToDB("login_with_Google");

        this.snackbarService.openSnackBar(this.snackbarService.messages.loginSuccess, null, "success");

        this.router.navigate(['file-upload'])
      })
      .catch((e) => {
        console.error(e);
        this.snackbarService.openSnackBar(this.snackbarService.messages.loginFailure, null, "error");
      })
      .finally(() => { this.loaderService.setLoading(false); });
  }




  addItem(item: UserProfile) {
    console.log(`this.UserProfile.doc(item.email)`)
    console.log(this.UserProfile.doc(item.email))


    let userProfileFirebase = this.UserProfile.doc(item.email).get();
    userProfileFirebase.subscribe(doc => {
      if (doc.exists) {
        console.log("Document data:", doc.data());

      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        this.UserProfile.doc(item.email).set(item)
      }
    })
  }

  updateItem(item) {
    console.log(`this.UserProfile.doc(item.email)`)
    console.log(this.UserProfile.doc(item.email))

    let userProfileFirebase = this.UserProfile.doc(item.email).get();
    userProfileFirebase.subscribe(doc => {
      if (doc.exists) {
        console.log("Document data:", doc.data());
        this.UserProfile.doc(item.email).update(item)
        this.snackbarService.openSnackBar(this.snackbarService.messages.profileUpdateSuccess, null, "success");
      } else {
        // doc.data() will be undefined in this case
        console.error("No such document!");
        this.snackbarService.openSnackBar(this.snackbarService.messages.profileUpdateFailure, null, "error");
      }
    })
  }


  updateUserProfile(firstName, lastName, company, designation, city, country) {

    let _self = this;
    this.loaderService.setLoading(true)

    this.afAuth.user.subscribe((user) => {
      user.updateProfile({
        displayName: firstName + " " + lastName
      })
        .then(() => {
          let item = {
            firstName: firstName,
            lastName: lastName,
            email: user.email,
            company: company,
            designation: designation,
            city: city,
            country: country,
            dateModified: serverTimestamp(),
          }
          _self.updateItem(item)
        })
        .catch((error) => {
          console.error(error.message)
          this.snackbarService.openSnackBar(this.snackbarService.messages.profileUpdateFailure, null, "error");
        })
        .finally(() => { this.loaderService.setLoading(false); });
    })
  }

  updateUserPassword(currentEmail, currentPassword, newPassword) {
        let credential = EmailAuthProvider.credential(currentEmail, currentPassword)
        if (!credential) {
          this.snackbarService.openSnackBar(this.snackbarService.messages.errorAuthenticating, null, "error");
        } else {
          this.afAuth.currentUser.then(user => {
            console.log(`this.updateUserPassword.caller`);
            console.log((new Error).stack);
            user.reauthenticateWithCredential(credential)
              .then(() => {
                console.log("Reauth successful");
                user.updatePassword(newPassword)
                  .then(result => {
                    console.log(result)
                    this.snackbarService.openSnackBar(this.snackbarService.messages.passwordUpdateSuccess, null, "success");
                  })
                  .catch(error => {
                    console.error(error);
                    this.snackbarService.openSnackBar(this.snackbarService.messages.passwordUpdateFailure, null, "error");
                  })
                })
              .catch(error => {
                console.error(error);
                this.snackbarService.openSnackBar(this.snackbarService.messages.errorAuthenticating, null, "error");
              })
          })
        }
  }

  proceedWithPasswordReset(email) {
    this.afAuth.sendPasswordResetEmail(email, {
      url: this.appUrl,
    })
      .then((res) => {
        console.log(res);
        this.snackbarService.openSnackBar("Email sent!", null, "success");
        this.router.navigate(['/login']);
      })
      .catch(error => {
        console.error(error);
        if (error.message.includes("user-not-found")) {
          this.snackbarService.openSnackBar(this.snackbarService.messages.loginUserNotFound, null, "error");
        }
        else if (error.message.includes("invalid-email")) {
          this.snackbarService.openSnackBar(this.snackbarService.messages.loginInvalidEmail, null, "error");
        }
        else {
          this.snackbarService.openSnackBar(this.snackbarService.messages.passwordResetMailError, null, "error");
        }
      })
  }

  signout() {
    this.afAuth.signOut();
  }
}