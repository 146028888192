import { Component, OnInit, ViewChild } from '@angular/core';
import { GetVisualizationDataService } from '../get-visualization-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-report-multivariate',
  templateUrl: './report-multivariate.component.html',
  styleUrls: ['./report-multivariate.component.css']
})
export class ReportMultivariateComponent implements OnInit {

  reportName: string;
  columnsToDisplay: any[];
  anomalyColumns: any[];
  dataColumns: any[];
  dataColumnsShadow: any[];
  dataColumnsFiltered: any[];
  sliderValueStart: number;
  sliderValueEnd: number;
  dataSource: any;
  dataSourceLength: any;
  threshold: number;
  multivariateHelpText: string;

  @ViewChild(MatPaginator, {static: false})
  set paginator(value: MatPaginator) {
    if (this.dataSource){
      this.dataSource.paginator = value;
    }
  }
  
  @ViewChild(MatSort, {static: false})
  set sort(value: MatSort) {
    if (this.dataSource){
      this.dataSource.sort = value;
    }
  }

  constructor(private getVisualizationDataService: GetVisualizationDataService, private route: ActivatedRoute, private router: Router) {
    this.reportName = "";
    this.columnsToDisplay = [];
    this.anomalyColumns = [];
    this.dataColumns = [];
    this.dataColumnsShadow = [];
    this.dataColumnsFiltered = [];
    this.threshold = 0.70;
    this.sliderValueStart = this.threshold;
    this.sliderValueEnd = 1.00;
    this.multivariateHelpText = `Rows having Anode Score 0.7 or above, are highlighted in the table below. Use the slider to filter.`
  }

  filterDataStart(row) {
    return row['Anode Score'] >= this;
  }

  filterDataEnd(row) {
    return row['Anode Score'] <= this;
  }

  onValChangeStart(event) {
    this.dataColumns = null;
    this.dataColumns = JSON.parse(JSON.stringify(this.dataColumnsShadow));
    this.dataColumns = this.dataColumns.filter(this.filterDataStart, event);
    this.dataColumns = this.dataColumns.filter(this.filterDataEnd, this.sliderValueEnd);
    this.dataSource.data = this.dataColumns;
  }

  onValChangeEnd(event) {
    this.dataColumns = null;
    this.dataColumns = JSON.parse(JSON.stringify(this.dataColumnsShadow));
    this.dataColumns = this.dataColumns.filter(this.filterDataStart, this.sliderValueStart);
    this.dataColumns = this.dataColumns.filter(this.filterDataEnd, event);
    this.dataSource.data = this.dataColumns;
  }

  onReset(event) {
    this.sliderValueStart = 0.0;
    this.sliderValueEnd = 1.00;

    this.dataColumns = null;
    this.dataColumns = JSON.parse(JSON.stringify(this.dataColumnsShadow));
    this.dataColumns = this.dataColumns.filter(this.filterDataStart, this.sliderValueStart);
    this.dataColumns = this.dataColumns.filter(this.filterDataEnd, this.sliderValueEnd);
    this.dataSource.data = this.dataColumns;
  }

  modifyColumnsNew(dataColumns) {
    for (let obj of dataColumns) {

      // Round values up to 2 decimal places
      obj["Anode Score"] = Math.round((+obj["Anode Score"] + Number.EPSILON) * 100) / 100;

      if ("Anode Anomaly Score" in obj) {
        delete obj["Anode Anomaly Score"];
      }

      if ("Anode Anomaly" in obj) {
        delete obj["Anode Anomaly"];
      }
    }
    return dataColumns;
  }

  ngOnInit() {

    let jsonURL = this.route.snapshot.paramMap.get('jsonURL');

    this.getVisualizationDataService.getResultsData(jsonURL).subscribe(data => {
      console.log("data")
      console.log(data)
      this.reportName = data.datasetName;
      this.dataColumns = data.multivariate;
      this.dataColumns = this.modifyColumnsNew(this.dataColumns);
      this.dataColumnsShadow = [...this.dataColumns];
      this.columnsToDisplay = Object.keys(this.dataColumns[0]);
      this.dataSource = new MatTableDataSource(this.dataColumns);
      this.dataSourceLength = this.dataSource.length;
      this.onValChangeStart(this.threshold);
    })
  }

  ngAfterViewInit() {
    if (this.paginator && this.dataSource) {
      this.dataSource.paginator = this.paginator;
    }
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }
}
