import { Component, OnInit } from '@angular/core';
import { NgxChartsModule, Color, ScaleType } from '@swimlane/ngx-charts';
import { GetVisualizationDataService } from '../get-visualization-data.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-report-health',
  templateUrl: './report-health.component.html',
  styleUrls: ['./report-health.component.css']
})
export class ReportHealthComponent implements OnInit {

  reportHealthData: any;
  view: [number, number];

  // options
  showXAxis: boolean;
  showYAxis: boolean;
  gradient: boolean;
  showLegend: boolean;
  showXAxisLabel: boolean;
  xAxisLabel: string;
  showYAxisLabel: boolean;
  yAxisLabel: string;

  //piechart only       
  legendPosition: string;
  showLabels: boolean;
  isDoughnut: boolean;
  arcWidth: number;

  colorScheme: string | Color;
  
  threshold: number;
  healthPercent: number;
  dataQuality: number;
  dataQualityText: string;
  dataQualityBGColor: string;
  dataQualityTextColor: string;

  constructor(private getVisualizationDataService: GetVisualizationDataService, private route: ActivatedRoute) {

    this.reportHealthData = null;
    this.view = [250, 200];

    this.showXAxis = false;
    this.showYAxis = false;
    this.gradient = false;
    this.showLegend = false;
    this.showXAxisLabel = false;
    this.xAxisLabel = 'Valid vs Invalid';
    this.showYAxisLabel = false;
    this.yAxisLabel = 'Classes';

    //piechart only
    this.legendPosition = 'below';
    this.showLabels = false;
    this.isDoughnut = true;
    this.arcWidth = 0.5;

    this.colorScheme = {
      domain: ['#51B53F', '#B53F51'],
      group: ScaleType.Ordinal,
      selectable: false,
      name: 'ValidInvalid',
    };

    this.threshold = 0.70;
    this.healthPercent = 0.0;
    this.dataQuality = 1;
    this.dataQualityText = "";
  }

  modifyColumnsNew(dataColumns) {
    for (let obj of dataColumns) {

      // Round values up to 2 decimal places
      obj["Anode Score"] = Math.round((+obj["Anode Score"] + Number.EPSILON) * 100) / 100;

      if ("Anode Anomaly Score" in obj) {
        delete obj["Anode Anomaly Score"];
      }

      if ("Anode Anomaly" in obj) {
        delete obj["Anode Anomaly"];
      }
    }
    return dataColumns;
  }


  ngOnInit() {
    let jsonURL = this.route.snapshot.paramMap.get('jsonURL');

    this.getVisualizationDataService.getResultsData(jsonURL).subscribe(data => {

      let validCount = 0;
      let invalidCount = 0;

      data['multivariate'] = this.modifyColumnsNew(data['multivariate']);

      for (let i = 0; i < data['multivariate'].length; i++) {
        if (data.multivariate[i]['Anode Score'] >= this.threshold) {
          ++invalidCount;
        } else {
          ++validCount;
        }
      }

      let summaryData =
      [
        {
          "name": "Valid",
          "value": validCount
        },
        {
          "name": "Invalid",
          "value": invalidCount
        }
      ]

      this.reportHealthData = summaryData;

      let healthVal = 100 - ((this.reportHealthData[1].value / (this.reportHealthData[1].value + this.reportHealthData[0].value))
        * 100)

      this.healthPercent = Math.round(healthVal * 100) / 100;

      if(this.healthPercent < 100) {
        this.dataQuality = 1;
        this.dataQualityText = "Excellent";
        this.dataQualityBGColor = "#01F049";
      }
      if(this.healthPercent < 75) {
        this.dataQuality = 4;
        this.dataQualityText = "Good";
        this.dataQualityBGColor = "#B3FA6B";
        this.dataQualityTextColor = "#000000";
      }
      if(this.healthPercent < 50) {
        this.dataQuality = 5;
        this.dataQualityText = "Poor";
        this.dataQualityBGColor = "#F56605";
        this.dataQualityTextColor = "#FFFFFF";
      }
      if(this.healthPercent < 25) {
        this.dataQuality = 7;
        this.dataQualityText = "Extremely Poor";
        this.dataQualityBGColor = "#F01B18";
        this.dataQualityTextColor = "#FFFFFF";
      }
    });
  }
}
