import { Component, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthenticationService } from '../shared/services/authentication.service';
import { Router } from "@angular/router";
import { EventsToDbService } from '@app/shared/services/events-to-db.service';



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {

  userVerified: boolean;

  constructor(
    public authenticationService: AuthenticationService,
    public auth: AngularFireAuth,
    private router: Router,
    public eventsToDbService: EventsToDbService
  ) { 
    this.userVerified = false;
  }

  async logout() {
    this.eventsToDbService.addEventToDB("clicked_logout_button");
    try {
      let val = await this.auth.signOut()
      this.router.navigate(['login'])
    } catch (error) {

    }
  }

  goToUpload() {
    this.eventsToDbService.addEventToDB("navigated_to_upload_page");
    this.router.navigate(['file-upload']);
  }

  goToWorkbench() {
    this.eventsToDbService.addEventToDB("navigated_to_workbench_page");
    this.router.navigate(['workbench']);
  }

  goToAbout() {
    this.eventsToDbService.addEventToDB("navigated_to_about_page");
    this.router.navigate(['about']);
  }

  goToSourceToTargetComparison() {
    this.eventsToDbService.addEventToDB("navigated_to_source_to_target_comparison");
    this.router.navigate(['source-to-target']);
  }

  goToHelp() {
    this.eventsToDbService.addEventToDB("navigated_to_help_page");
    this.router.navigate(['user-help']);
  }

  goToFAQs() {
    this.eventsToDbService.addEventToDB("navigated_to_help_page");
    this.router.navigate(['faqs']);
  }


  goToReportsList() {
    this.eventsToDbService.addEventToDB("navigated_to_reports_list_page");
    this.router.navigate(['reports-list']);
  }

  goToProfile() {
    this.eventsToDbService.addEventToDB("navigated_to_profile_page");
    this.router.navigate(['user-profile'])
  }

  goToReport() {
    this.eventsToDbService.addEventToDB("navigated_to_report_page");
    this.router.navigate(['/report-detailed', './assets/data_unified.json']);
  }

  ngOnInit() {
    this.authenticationService.afAuth.user.subscribe(
      (user: any) => {
        if (user) {
          if (user.emailVerified) {
            this.userVerified = true;
          }
        }
      })
  }

}
