<mat-card class="w-3/5 mt-10 mb-10 m-auto flex justify-center shadow-xl rounded-xl p-5" appearance="outlined">
    <mat-card-header class="text-2xl mb-6 m-auto justify-center">
        <mat-card-title>
            Password
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <div class="flex flex-col justify-center m-auto">
            <div class="text-xl text-bold">
                Email:
            </div>
            <div class="mb-2">
                {{this.currentEmail}}
            </div>
            <form [formGroup]="userPasswordForm" novalidate
                class="h-fit w-3/4 flex flex-col m-auto p-10" *ngIf="this.userRetrieved">
                <div class="col">
                    <mat-form-field class="w-4/5">
                        <input matInput placeholder="Current Password" formControlName="currentPassword" #currentPassword type="password"
                            [disabled]="true">
                        <mat-error *ngIf="userPasswordForm.controls['currentPassword'].hasError('required')">
                            Current password is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field class="w-4/5">
                        <input matInput placeholder="New Password" formControlName="newPassword" #newPassword [type]="showPassword ? 'text' : 'password'"
                            [disabled]="true">
                        <mat-icon matSuffix (click)="togglePasswordVisibility()">
                            {{showPassword?'visibility_off':'visibility'}}
                        </mat-icon>
                        <mat-error *ngIf="userPasswordForm.controls['newPassword'].hasError('required')">New password is <strong>required</strong>
                        </mat-error>
                        <mat-error>{{errorMessagePassword}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col mt-20">
                    <mat-card-actions class="justify-center">
                        <button mat-raised-button color="primary" [disabled]="!userPasswordForm.valid || !userPasswordForm.dirty" (click)="
              callUpdatePassword(this.currentEmail, currentPassword.value, newPassword.value)
              ">Update</button>
                    </mat-card-actions>
                </div>
                
                <input type="submit" hidden [disabled]="!userPasswordForm.valid || !userPasswordForm.dirty"
                (click)="callUpdatePassword(this.currentEmail, currentPassword.value, newPassword.value)" />
            </form>
        </div>
    </mat-card-content>
</mat-card>