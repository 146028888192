
export const environment = {
   production: false,
   firebase: {
    projectId: 'anodeapp-beta',
    appId: '1:942963708322:web:2f6a79eb2e3785a190a2fb',
    storageBucket: 'anodeapp-beta.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyDnfqUEPmqBxPgSNW1z78_xszxTl6z8iP0',
    authDomain: 'anodeapp-beta.firebaseapp.com',
    messagingSenderId: '942963708322',
    measurementId: 'G-YCP480D7MW'
   }
};