import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthenticationService } from './shared/services/authentication.service';
import { LoaderService } from './shared/services/loader.service';
import { Router } from "@angular/router";
import {MatSnackBar} from '@angular/material/snack-bar';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'anode-beta';

  isSmallScreen: boolean;

  constructor(
    public authenticationService: AuthenticationService, 
    public auth: AngularFireAuth, 
    private router: Router, 
    public loaderService:LoaderService,
    private breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit() {
    this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe(result => {
        this.isSmallScreen = result.matches;
      });
  }
}