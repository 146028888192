import { Component, ElementRef, ViewChild, inject, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FieldValue, serverTimestamp, Timestamp } from '@angular/fire/firestore';
import { SnackbarService } from '@app/shared/services/snackbar.service';
import { EventsToDbService } from '@app/shared/services/events-to-db.service';
import { Router } from "@angular/router";
import { DataPreviewMultiService } from '@app/shared/services/data-preview-multi.service';
import { SourceToTargetComparisonService } from '@app/shared/services/source-to-target-comparison.service';
// import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';


export interface UserHistorySourceToTarget {
  email: string;
  timestamp: Timestamp | FieldValue;
  lastModifiedUTC: string;
  fileNameSource: string;
  fileNameTarget: string;
  inputFileLocationSource: string;
  inputFileLocationTarget: string;
  outputFileLocationJSON: string;
  outputFileLocationPDF: string;
  lastModified: Timestamp | FieldValue;
}


@Component({
  selector: 'app-source-to-target',
  templateUrl: './source-to-target.component.html',
  styleUrls: ['./source-to-target.component.css']
})
export class SourceToTargetComponent implements OnInit {

  private uploadStatus = new Subject<boolean>();
  uploadStatus$ = this.uploadStatus.asObservable();

  sourceFile: File | null = null;
  targetFile: File | null = null;

  sourceData: any[] = [];
  targetData: any[] = [];
  comparisonResults: string[] = [];

  sourceFilePath: string;
  targetFilePath: string;

  // private datBS = new BehaviorSubject<any>(null);

  uploadPercent: Observable<number> | null;
  uploadProgressValue: number;
  downloadURL: Observable<string> | null;
  private userHistorySourceToTarget: AngularFirestoreCollection<UserHistorySourceToTarget>;
  items: Observable<UserHistorySourceToTarget[]>;
  notesAccordionOpen: Boolean = true;
  fileAccordionOpen: Boolean = true;
  @ViewChild('fileInput') fileInputVariableSource: ElementRef;
  @ViewChild('fileInput') fileInputVariableTarget: ElementRef;

  constructor(
    private storage: AngularFireStorage,
    private afs: AngularFirestore,
    public auth: AngularFireAuth,
    private snackbarService: SnackbarService,
    public eventsToDbService: EventsToDbService,
    private router: Router,
    // private dataPreviewComponent: DataPreviewComponent
    private dataPreviewMultiService: DataPreviewMultiService,
    private sourceToTargetComparisonService: SourceToTargetComparisonService
  ) {
    this.userHistorySourceToTarget = afs.collection<UserHistorySourceToTarget>('UserHistorySourceToTarget');
    this.items = this.userHistorySourceToTarget.valueChanges();
    this.sourceFile = null;
    this.targetFile = null;
    this.sourceFilePath = null;
    this.targetFilePath = null;
    this.uploadPercent = null;
    this.uploadProgressValue = 0;
    this.downloadURL = null;
    this.dataPreviewMultiService.setDataFileSource(this.sourceFile)
    this.dataPreviewMultiService.setDataFileTarget(this.targetFile)
  }

  onSourceFilechange(event: any) {
    console.log(event.target.files[0])
    this.sourceFile = event.target.files[0]
    this.dataPreviewMultiService.setDataFileSource(this.sourceFile)
  }

  onTargetFilechange(event: any) {
    console.log(event.target.files[0])
    this.targetFile = event.target.files[0]
    this.dataPreviewMultiService.setDataFileTarget(this.targetFile)
  }

  constructFilePath(email, name) {
    return email + '__' + name.split(".")[0] + '__' + Date.now() + '.' + name.split(".").at(-1)
  }

  validateFile(file): boolean {
    // If there is no file
    if (!file) {
      this.snackbarService.openSnackBar(this.snackbarService.messages.fileNotSelected, null, "error");
      return false;
    }

    // File size cannot be greater than 500 KB
    if (file.size > 500000) {
      this.snackbarService.openSnackBar(this.snackbarService.messages.fileSizeExceeded, null, "error");
      return false;
    }

    // File type should be csv or Excel
    if (file.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      && file.type != "text/csv"
      && file.type != "application/vnd.ms-excel") {
      this.snackbarService.openSnackBar(this.snackbarService.messages.fileTypeInvalid, null, "error");
      return false;
    }
    return true;
  }

  async uploadSource(item) {

    if (this.validateFile(this.sourceFile) == false) {
      this.fileInputVariableSource.nativeElement.value = "";
      this.sourceFile = null;
      this.dataPreviewMultiService.setDataFileSource(this.sourceFile)
      return null;
    }

    this.uploadPercent = null;
    this.downloadURL = null;

    this.auth.user.subscribe((user) => {
      this.sourceFilePath = this.constructFilePath(user.email, this.sourceFile.name)

      let fileRef = this.storage.ref(this.sourceFilePath)
      let task = this.storage.upload(this.sourceFilePath, this.sourceFile)

      this.uploadPercent = task.percentageChanges();

      this.uploadPercent.subscribe((val) => { this.uploadProgressValue = val })


      task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL()

          this.fileInputVariableSource.nativeElement.value = "";
          this.sourceFile = null;
          this.dataPreviewMultiService.setDataFileSource(this.sourceFile)

          let serverTs = serverTimestamp();

          this.downloadURL.subscribe((downloadURL) => {
            this.auth.user.subscribe((user) => {
              item.timestamp = serverTs,
                item.fileNameSource = this.sourceFilePath,
                item.inputFileLocationSource = downloadURL,
                item.lastModified = serverTs,
                item.lastModifiedUTC = new Date().toUTCString()

              let docName = this.getDocName()

              this.addItem(item, docName).then((docRef) => {
                this.eventsToDbService.addEventToDB("Uploaded_File_Source");
                this.sourceFilePath = null;
                // this.uploadStatus.next(true);
                this.sourceToTargetComparisonService.emitUploadStatus(this.userHistorySourceToTarget.doc(docName).ref.id);
              })
                .catch(() => {
                  // this.uploadStatus.next(false)
                  this.sourceToTargetComparisonService.emitUploadStatus(null);
                })
            })
          })
        })
      )
        .subscribe()
    })
  }


  getDocName() {
    return this.targetFilePath.split('__')[0] + '__' + this.targetFilePath.split('__')[1] + '__' + this.targetFilePath.split('__')[2].split('.')[0]
  }


  async uploadTarget() {
    this.eventsToDbService.addEventToDB("clicked_upload_button_source_to_target");

    if (this.validateFile(this.targetFile) == false) {
      this.fileInputVariableTarget.nativeElement.value = "";
      this.targetFile = null;
      this.dataPreviewMultiService.setDataFileSource(this.targetFile)
      return null;
    }

    this.uploadPercent = null;
    this.downloadURL = null;

    this.auth.user.subscribe((user) => {
      this.targetFilePath = this.constructFilePath(user.email, this.targetFile.name)

      let fileRef = this.storage.ref(this.targetFilePath)
      let task = this.storage.upload(this.targetFilePath, this.targetFile)

      this.uploadPercent = task.percentageChanges();

      this.uploadPercent.subscribe((val) => { this.uploadProgressValue = val })


      task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL()

          this.fileInputVariableTarget.nativeElement.value = "";
          this.targetFile = null;
          this.dataPreviewMultiService.setDataFileTarget(this.targetFile)

          let serverTs = serverTimestamp();

          this.downloadURL.subscribe((downloadURL) => {
            this.auth.user.subscribe((user) => {
              let item: UserHistorySourceToTarget = {
                email: user.email,
                timestamp: serverTs,
                fileNameSource: '',
                fileNameTarget: this.targetFilePath,
                inputFileLocationSource: '',
                inputFileLocationTarget: downloadURL,
                outputFileLocationJSON: "",
                outputFileLocationPDF: "",
                lastModified: serverTs,
                lastModifiedUTC: new Date().toUTCString()
              }
              this.uploadSource(item);
            })
          })
        })
      )
        .subscribe()

    })
  }

  addItem(item: UserHistorySourceToTarget, docName: string): Promise<void> {
    // this.userHistory.add(item);
    return this.userHistorySourceToTarget.doc(docName).set(item)
  }


  addItemSource(item: UserHistorySourceToTarget) {
    // this.userHistory.add(item);
    let docName = this.sourceFilePath.split('__')[0] + '__' + this.sourceFilePath.split('__')[1] + '__' + this.sourceFilePath.split('__')[2].split('.')[0]
    this.userHistorySourceToTarget.doc(docName).set(item)
    this.eventsToDbService.addEventToDB("uploaded_File");
    this.sourceFilePath = null;
  }

  addItemTarget(item: UserHistorySourceToTarget) {
    // this.userHistory.add(item);
    let docName = this.targetFilePath.split('__')[0] + '__' + this.targetFilePath.split('__')[1] + '__' + this.targetFilePath.split('__')[2].split('.')[0]
    this.userHistorySourceToTarget.doc(docName).set(item)
    this.eventsToDbService.addEventToDB("uploaded_File");
    this.targetFilePath = null;
  }

  goToReportsList() {
    this.eventsToDbService.addEventToDB("navigated_to_reports_list_page");
    this.router.navigate(['reports-list']);
  }

  // getExcelData() {
  //   return this.datBS.asObservable();
  // }

  // loadData(dat, dest) {
  //   dest = []
  //   if (dat !== null) {
  //     dest = dat
  //   }
  // }

  ngOnInit() {
    // this.dataPreviewMultiService.getDataColumnsSource().subscribe(dat => {
    //   console.log("source")
    //   console.log(dat)
    //   this.loadData(dat, this.sourceData)
    // })


    // this.dataPreviewMultiService.getDataColumnsTarget().subscribe(dat => {
    //   console.log("target")
    //   console.log(dat)
    //   this.loadData(dat, this.targetData)
    // })
  }

  // compareTables() {

  //   console.log(this.sourceData)
  //   console.log(this.targetData)

  //   if (this.sourceData.length === 0 || this.targetData.length === 0) {
  //     console.log("Hello")
  //     return;
  //   }

  //   this.comparisonResults = [];

  //   this.sourceData.forEach(sourceItem => {
  //     const targetItem = this.targetData.find(item => item.id === sourceItem.id);

  //     if (targetItem) {
  //       const differences = this.compareItems(sourceItem, targetItem);
  //       if (differences.length > 0) {
  //         this.comparisonResults.push(`Differences for ID ${sourceItem.id}: ${differences.join(', ')}`);
  //       }
  //     } else {
  //       this.comparisonResults.push(`No match found for source ID ${sourceItem.id}`);
  //     }
  //   });

  //   this.targetData.forEach(targetItem => {
  //     const sourceItem = this.sourceData.find(item => item.id === targetItem.id);

  //     if (!sourceItem) {
  //       this.comparisonResults.push(`No match found for target ID ${targetItem.id}`);
  //     }
  //   });
  // }

  // compareItems(sourceItem: any, targetItem: any): string[] {
  //   const differences: string[] = [];

  //   if (sourceItem.name !== targetItem.name) {
  //     differences.push(`Name (source: ${sourceItem.name}, target: ${targetItem.name})`);
  //   }

  //   // Add more fields comparison if needed

  //   return differences;
  // }


  uploadDataForComparison() {
    // this.uploadSource()
    this.uploadTarget()
  }
}