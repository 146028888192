import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { finalize } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FieldValue, serverTimestamp, Timestamp } from '@angular/fire/firestore';

export interface UserHistorySourceToTarget {
  email: string;
  timestamp: Timestamp | FieldValue;
  lastModifiedUTC: string;
  fileNameSource: string;
  fileNameTarget: string;
  inputFileLocationSource: string;
  inputFileLocationTarget: string;
  outputFileLocationJSON: string;
  outputFileLocationPDF: string;
  lastModified: Timestamp | FieldValue;
}


@Injectable({
  providedIn: 'root'
})
export class SourceToTargetComparisonService {

  private uploadStatus = new Subject<string>();
  uploadStatus$ = this.uploadStatus.asObservable();
  private userHistorySourceToTarget: AngularFirestoreCollection<UserHistorySourceToTarget>;

  
  emitUploadStatus(status: string) {
    this.uploadStatus.next(status);
  }

  dataURL: string;

  constructor(
    private http: HttpClient,
    private storage: AngularFireStorage,
    private afs: AngularFirestore,
    public auth: AngularFireAuth,
  ) {
    this.userHistorySourceToTarget = afs.collection<UserHistorySourceToTarget>('UserHistorySourceToTarget');
  }

  public setURL(url) {
    this.dataURL = url;
  }

  getItem(id: string): AngularFirestoreDocument<any> {
    return this.userHistorySourceToTarget.doc(id);
  }

  public getSourceToTargetResultsData(): Observable<any> {
    if (this.dataURL) {
      return this.http.get(this.dataURL);
    }
    return null;
  }
}