import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { EventsToDbService } from '@app/shared/services/events-to-db.service';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { Router } from "@angular/router";
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { FormValidationAssistanceService } from '@app/shared/services/form-validation-assistance.service';

export interface UserProfile {
  password: string;
}

@Component({
  selector: 'app-user-password-settings',
  templateUrl: './user-password-settings.component.html',
  styleUrls: ['./user-password-settings.component.css']
})
export class UserPasswordSettingsComponent {
  private UserProfile: AngularFirestoreCollection<UserProfile>;

  userRetrieved: boolean;
  currentEmail: string;
  showPassword: boolean;
  errorMessagePassword: string;

  constructor(
    public authenticationService: AuthenticationService,
    public eventsToDbService: EventsToDbService,
    private fb: FormBuilder,
    public auth: AngularFireAuth,
    private router: Router,
    private afs: AngularFirestore,
    private formValidationAssistanceService: FormValidationAssistanceService) {
    this.userRetrieved = false;
    this.showPassword = false;
  }

  userPasswordForm = this.fb.group({
    currentPassword: ["", Validators.required],
    newPassword: [
      "",
      [
        Validators.required,
        Validators.minLength(this.formValidationAssistanceService.getPasswordMinLength()),
        Validators.pattern(this.formValidationAssistanceService.getPasswordRegexString())
      ]
    ],
  });

  callUpdatePassword(currentEmail, currentPassword, newPassword) {
    this.eventsToDbService.addEventToDB("updated_user_password");
    this.authenticationService.updateUserPassword(currentEmail, currentPassword, newPassword);
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  ngOnInit() {
    this.auth.user.subscribe(
      (user: any) => {
        if (user) {
          this.userRetrieved = true;
          this.currentEmail = user.email;
        } else {
          this.errorMessagePassword = this.formValidationAssistanceService.getErrorMessage("password")
        }
      }
    )
  }

}
