import { Component } from '@angular/core';

@Component({
  selector: 'app-configure-before-upload',
  templateUrl: './configure-before-upload.component.html',
  styleUrls: ['./configure-before-upload.component.css']
})
export class ConfigureBeforeUploadComponent {
  panelOpenState = true;
}
