<div class="flex flex-col justify-center m-auto login-card rounded-xl h-full w-full">
  <h1 class="flex flex-col justify-end text-3xl font-bold h-1/6 mt-[2%] mb-2">
    {{this.welcomeMessage}}
  </h1>

  <form [formGroup]="loginForm" novalidate class=" w-full mb-4 m-auto pl-[14%] pr-[14%]">

    <mat-form-field class="w-full mt-2">
      <input matInput type="text" class="formControl" formControlName="userEmail" placeholder="Email" #userEmail
        required />
      <mat-error *ngIf="loginForm.controls['userEmail'].hasError('required')">Email is <strong>required</strong>
      </mat-error>
      <mat-error>{{errorMessageEmail}}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-full">
      <input matInput class="formControl" formControlName="userPassword" placeholder="Password" #userPassword required
        #newPassword [type]="showPassword ? 'text' : 'password'" />
      <mat-icon matSuffix (click)="togglePasswordVisibility()">
        {{showPassword?'visibility_off':'visibility'}}
      </mat-icon>
      <mat-error *ngIf="loginForm.controls['userPassword'].hasError('required')">Password is
        <strong>required</strong>
      </mat-error>
      <mat-error>{{errorMessagePassword}}
      </mat-error>
    </mat-form-field>

    <input type="submit" hidden [disabled]="!loginForm.valid"
      (click)="callLogin(userEmail.value, userPassword.value)" />
    <div class="mt-[15%]">
      <button mat-flat-button type="button" class="btn btnPrimary w-full h-12 text-white mt-[5%]" color="primary"
        [disabled]="!loginForm.valid" (click)="callLogin(userEmail.value, userPassword.value)">Log In</button>
    </div>

    <div class="mt-2 mb-2">
      OR
    </div>
    <div>
      <button mat-flat-button type="button" color="accent" class="btn btnPrimary w-full h-12"
        (click)="callLoginWithGoogle()">Continue with Google</button>
    </div>

  </form>

    <div class="text-base mb-[5%]">
      <a class="underline cursor-pointer" (click)="navigateToForgotPassword()">Forgot Password? </a>
    </div>

  <div class="m-auto pl-32 pr-32">
    <div class="redirectToLogin">
      <div class="text-base mb-2">Don't have an account yet? </div>

      <button class="w-full h-12" mat-flat-button color=primary (click)="navigateToSignup()">
        Sign Up
      </button>
    </div>

    <div class="text-base mt-8 mb-5">
      &copy; {{this.copyrightMessage}}
    </div>
  </div>
</div>