<div class="w-[99vw] p-10">
    <mat-card class="shadow-xl rounded-xl p-5 -mt-10" appearance="outlined">
        <mat-card-header class="text-2xl mb-6 -mt-2 font-bold flex justify-center">
            <mat-card-title>
                Are you struggling with inaccurate and unreliable data?
            </mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <div class="flex justify-center m-auto">

                <article class="prose max-w-none w-2/4">
                    
                    <h3>Don't let poor data quality hinder your business performance any longer.
                    </h3>

                    <h3>
                        <span>Anode</span>, an AI-powered innovative digital solution designed to revolutionize data quality
                        management for businesses is here to help.
                    </h3>
                    <br>


                    <h3>Data Quality Challenges</h3>
                    Data quality issues can have a significant impact on business operations, decision-making, and overall performance.
                    Incomplete and inconsistent data can lead to costly errors, missed opportunities, and hindered growth.
                    We understand these challenges and have developed Anode to help businesses overcome data quality hurdles effectively.
                    <br>
                    <br>

                    <h3>Key Benefits of Anode</h3>
                    With Anode, you can experience a transformative shift in your data quality management.
                    Our powerful features ensure reliable analysis of data that you can leverage to drive critical business decisions.
                    Benefit from improved accuracy, increased operational efficiency, and enhanced decision-making.
                    <br>
                    <br>

                    <hr>

                    <figure class="flex justify-center m-auto">
                        <img src="https://storage.googleapis.com/anode-beta-assets/CodygonLogoSocialMedia_400x400.png" alt="" />
                    </figure>

                    <br>

                    <p>Anode is a product of Codygon
                    </p>

                    <a href="https://bit.ly/3C8CIAw" target="_blank"><button mat-button class="bg-blue-700 text-white m-8 p-6 rounded-full"> Visit Codygon </button></a>
                    
                    

                </article>
            </div>
        </mat-card-content>
    </mat-card>
    <app-cta-contact></app-cta-contact>
</div>