import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventsToDbService } from '@app/shared/services/events-to-db.service';


@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private _snackBar: MatSnackBar,
    public eventsToDbService: EventsToDbService
  ) { }

  messages = {
    loginInvalidEmail: `Sorry! Please check the email you've entered`,
    loginWrongPassword: `Sorry! Please check the password you've entered`,
    loginUserNotFound: `Sorry! It looks like you didn't sign up yet`,
    loginFailure: `Sorry! There was an error while logging in`,
    loginSuccess: `Logged in successfully!`,
    signupEmailAlreadyInUse: `Hmmm...it looks like you're already signed up with us`,
    signupFailure: `Sorry! There was an error during sign up`,
    signupSuccess: `You have been successfully registered!`,
    fileNotSelected: `Please select a file first`,
    fileSizeExceeded: `The size of the file is greater than 500 KB`,
    fileTypeInvalid: `The file type is not valid`,
    profileUpdateSuccess: `Your profile was updated successfully!`,
    profileUpdateFailure: `There was an error updating your profile`,
    validateEmail: `Please validate your email address. Kindly check your inbox.`,
    errorAuthenticating: `Sorry! There was an error authenticating`,
    passwordUpdateSuccess: `Updated password successfully`,
    passwordUpdateFailure: `Sorry! There was an error updating the password`,
    passwordResetMailError: `Sorry! There was an error sending the password reset email`,
    ruleNameNotProvided: 'Please provide a name for the rule before saving.',
    ruleEmpty: 'Please add a rule to save'
  }

  openSnackBar(message: string, action: string, type: string) {

    this.eventsToDbService.addEventToDB("[" + type + "] " + message);

    this._snackBar.open(
      message,
      action,
      {
        duration: 4000,
        panelClass: ['snackbar-' + type]
      });

  }

}
