<div>
    <div class="align-middle">
        <button mat-raised-button color="primary" (click)="resetFiltering()" class="w-5 mt-2">
            Reset
        </button>
    </div>
    <div class="mt-2">
        <mat-radio-group [(ngModel)]="createRule">
            <mat-card class="mt-2">
                <mat-card-header>
                    <mat-card-title class="text-left font-bold">
                        <mat-radio-button [value]=false [checked]="!createRule">
                            Select a rule
                        </mat-radio-button>
                    </mat-card-title>
                   
                </mat-card-header>

                <span>Navigate up to the table to see the result</span>

                <mat-card-content class="ml-10">
                    <mat-radio-group *ngIf="createRule == false" [(ngModel)]="selectedRule"
                        class="flex flex-col text-left">
                        <div *ngIf="rules && rules.length == 0" class="ml-11">
                            No rules added yet! Create one below.
                        </div>
                        <mat-radio-button *ngFor="let ruleItem of rules" [value]="ruleItem.rule"
                            (click)="getFilteredRecords(ruleItem.rule)">

                            <div class="font-medium">
                                {{ruleItem.name}}
                            </div>
                            <div class="italic">
                                {{ruleItem.rule}}
                            </div>
                        </mat-radio-button>
                    </mat-radio-group>
                </mat-card-content>
            </mat-card>

            <mat-card class="mt-2">
                <mat-card-header>
                    <mat-card-title class="text-left font-bold">
                        <mat-radio-button [value]=true>
                            Create a rule
                        </mat-radio-button>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>

                    <div *ngIf="createRule == true">

                        <div *ngIf="conditions && conditions.length > 0" class="mt-2 mb-2">
                            If you don't see any value in the <mat-chip>Select Unique Value</mat-chip> dropdown after selecting a value for the <mat-chip>Field</mat-chip> dropdown, click the Reset button.
                        </div>
                
                        <!-- Logical operator radio buttons -->
                        <div *ngIf="conditions.length > 0">
                            GROUPING: 
                            <mat-radio-group [(ngModel)]="logicalOperator">
                                <mat-radio-button value="AND">AND</mat-radio-button>
                                <mat-radio-button value="OR">OR</mat-radio-button>
                            </mat-radio-group>
                        </div>

                        
                        <!-- Conditions form -->
                        <div *ngFor="let condition of conditions; let i = index">
                            <mat-card class="mt-2 mb-2 shadow-xl rounded-xl" appearance="outlined">
                                <mat-card-content>
                                    <mat-form-field class="mr-2">
                                        <mat-label>Field</mat-label>
                                        <mat-select [(ngModel)]="condition.field">
                                            <!-- <mat-option *ngFor="let field of fields" [value]="field">{{ field }}</mat-option> -->
                                            <mat-option *ngFor="let dat of dataProfile;" [value]="dat.value">{{
                                                dat.value
                                                }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field class="mr-2">
                                        <mat-label>Operator</mat-label>
                                        <mat-select [(ngModel)]="condition.operator">
                                            <mat-option *ngFor="let op of operators" [value]="op">{{ op }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-radio-group [(ngModel)]="condition.valueOrCustom">
                                        <mat-radio-button [value]="'unique'" class="mr-2">
                                            Select Unique Value
                                        </mat-radio-button>
                                        <mat-form-field *ngIf="condition.valueOrCustom === 'unique'" class="mr-2">
                                            <mat-label>Select Unique Value</mat-label>
                                            <mat-select [(ngModel)]="condition.value">
                                                <mat-option *ngFor="let value of getUniqueValues(condition.field)"
                                                    [value]="value">{{value}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-radio-button [value]="'custom'" class="mr-2">
                                            Enter Custom Value
                                        </mat-radio-button>
                                        <mat-form-field *ngIf="condition.valueOrCustom === 'custom'" class="mr-2">
                                            <mat-label>Enter Custom Value</mat-label>
                                            <input matInput [(ngModel)]="condition.customValue"
                                                placeholder="Custom Value" />
                                        </mat-form-field>
                                    </mat-radio-group>
                                    <button mat-mini-fab color="warn" (click)="removeCondition(i)" class="ml-4">
                                        <mat-icon>remove</mat-icon>
                                    </button>
                                </mat-card-content>
                            </mat-card>
                        </div>

                        <!-- Plus symbol button for adding conditions -->
                        <button mat-mini-fab color="primary" (click)="addCondition()" class="mt-2 mb-2">
                            <mat-icon>add</mat-icon>
                        </button>

                        <!-- Build rule button -->
                        <div *ngIf="conditions.length > 0" class="mt-2 mb-2">
                            <p class="mb-2"> After applying the rule, you can see the changes in the data table above </p>
                            <button mat-raised-button color="primary" (click)="buildRule()">Apply Rule</button>
                        </div>
                        

                        <!-- Rule display -->
                        <div *ngIf="rule">
                            <h3>Current rule:</h3>
                            <p>{{ rule }}</p>
                        </div>

                        <hr>

                        <!--Rule saving-->
                        <div *ngIf="rule" class="mt-5">
                            <h1>Save rule</h1>
                            <mat-form-field>
                                <mat-label>Rule Name</mat-label>
                                <input matInput [(ngModel)]="ruleName" placeholder="Enter rule name" />
                            </mat-form-field>
                            <br />
                            <button mat-raised-button color="primary" (click)="saveRule()"
                                [disabled]="ruleName.length == 0">
                                Save Rule</button>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </mat-radio-group>
    </div>
</div>