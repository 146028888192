import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataPreviewService {

  private fileBS = new BehaviorSubject<any>(null);
  private dataColumnsBS = new BehaviorSubject<any>(null);

  constructor(
  ) {}

  setDataFile(data: any) {
    this.fileBS.next(data)
  }

  getDataFile() {
    return this.fileBS.asObservable();
  }

  setDataColumns(dat: any) {
    this.dataColumnsBS.next(dat)
  }

  getDataColumns() {
    return this.dataColumnsBS.asObservable();
  }

}
