import { Component, inject } from '@angular/core';
import { EventsToDbService } from '@app/shared/services/events-to-db.service';

@Component({
  selector: 'app-cta-sales',
  templateUrl: './cta-sales.component.html',
  styleUrls: ['./cta-sales.component.css']
})
export class CtaSalesComponent {

  constructor(
    public eventsToDbService: EventsToDbService
   ) {}

  sendMailToSales() {
    this.eventsToDbService.addEventToDB("clicked_cta_button_sales");
  }
}
