<div class="w-[99vw] p-10">
    <mat-card class="shadow-xl rounded-xl p-5 -mt-10" appearance="outlined">
        <mat-card-header class="text-2xl mb-6 -mt-2 font-bold flex justify-center">
            <mat-card-title>
                Help
            </mat-card-title>
        </mat-card-header>

        <div class="flex justify-center m-auto">
            <iframe width="1120" height="630" src="https://www.youtube.com/embed/Oap4NCmx7Vo?si=NXDjwETOmiw59NMR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>

        <mat-card-content>
            <div class="flex justify-center m-auto">
                <img src="../assets/anomaly-classes.png" alt="">
            </div>
        </mat-card-content>
    </mat-card>
    
    <app-cta-contact></app-cta-contact>
</div>