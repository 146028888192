import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import {Router} from "@angular/router";
import { FormValidationAssistanceService } from '@app/shared/services/form-validation-assistance.service';

@Component({
  selector: 'signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent {

  showPassword: boolean;
  errorMessageEmail: string;
  errorMessagePassword: string;

  constructor(
    public authenticationService: AuthenticationService, 
    private fb: FormBuilder, 
    public auth: AngularFireAuth, 
    private router: Router,
    private formValidationAssistanceService: FormValidationAssistanceService
  ) {
    this.showPassword = false;
  }

  signupForm = this.fb.group({
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    company: null,
    designation: null,
    city: [null, Validators.required],
    country: [null, Validators.required],
    email: [null, [Validators.required, Validators.email]],
    password: [
      "",
      [
        Validators.required,
        Validators.minLength(this.formValidationAssistanceService.getPasswordMinLength()),
        Validators.pattern(this.formValidationAssistanceService.getPasswordRegexString())
      ]
    ],
  });

  callSignup(email, password, firstName, lastName, company, designation, city, country) {
    this.authenticationService.signup(email, password, firstName, lastName, company, designation, city, country)
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  ngOnInit() {
    this.auth.user.subscribe(
      (result: any) => {
        if (result) { this.router.navigate(['login']) }
        else {
          this.errorMessageEmail = this.formValidationAssistanceService.getErrorMessage("email")
          this.errorMessagePassword = this.formValidationAssistanceService.getErrorMessage("password")
        }
      }
    )
  }

}


