import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataPreviewMultiService {
  private fileBSSource = new BehaviorSubject<any>(null);
  private fileBSTarget = new BehaviorSubject<any>(null);
  private dataColumnsBSSource = new BehaviorSubject<any>(null);
  private dataColumnsBSTarget = new BehaviorSubject<any>(null);

  constructor(
  ) {}

  setDataFileSource(data: any) {
    this.fileBSSource.next(data)
  }

  getDataFileSource() {
    return this.fileBSSource.asObservable();
  }

  setDataColumnsSource(dat: any) {
    this.dataColumnsBSSource.next(dat)
  }

  getDataColumnsSource() {
    return this.dataColumnsBSSource.asObservable();
  }


  setDataFileTarget(data: any) {
    this.fileBSTarget.next(data)
  }

  getDataFileTarget() {
    return this.fileBSTarget.asObservable();
  }

  setDataColumnsTarget(dat: any) {
    this.dataColumnsBSTarget.next(dat)
  }

  getDataColumnsTarget() {
    return this.dataColumnsBSTarget.asObservable();
  }

}
