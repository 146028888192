import { Component } from '@angular/core';

@Component({
  selector: 'app-cta-contact',
  templateUrl: './cta-contact.component.html',
  styleUrls: ['./cta-contact.component.css']
})
export class CtaContactComponent {

}
