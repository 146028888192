import { Component, OnInit, ViewChild } from '@angular/core';
import { read, utils } from 'xlsx';
import { SourceToTargetComparisonService } from '@app/shared/services/source-to-target-comparison.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
// import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-data-preview-source-to-target',
  templateUrl: './data-preview-source-to-target.component.html',
  styleUrls: ['./data-preview-source-to-target.component.css']
})
export class DataPreviewSourceToTargetComponent implements OnInit {
  reportName: string;
  columnsToDisplay: any[];
  anomalyColumns: any[];
  dataColumns: any[];
  dataColumnsShadow: any[];
  dataColumnsFiltered: any[];
  sliderValueStart: number;
  sliderValueEnd: number;
  dataSource: any = null;
  dataSourceLength: any;
  threshold: number;
  multivariateHelpText: string;
  arrayBuffer: any;
  panelOpenState: Boolean = true;
  private datBS = new BehaviorSubject<any>(null);
  placeholderText: string;

  data$: Observable<string | null>;
  documentId: string;

  isSubmitSuccessful = false;


  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }

  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  constructor(
    private sourceToTargetComparisonService: SourceToTargetComparisonService
  ) {
    this.reportName = "";
    this.columnsToDisplay = [];
    this.anomalyColumns = [];
    this.dataColumns = [];
    this.dataColumnsShadow = [];
    this.dataColumnsFiltered = [];
    this.placeholderText = "Please wait for the results to be processed..."
  }

  onReset(event) {
    this.dataColumns = null;
    this.dataColumns = JSON.parse(JSON.stringify(this.dataColumnsShadow));
    this.dataSource.data = this.dataColumns;
  }


  getData() {
      this.sourceToTargetComparisonService.getSourceToTargetResultsData().subscribe(data => {
        console.log("data")
        console.log(data)
        this.reportName = data.datasetName;
        this.dataColumns = data;
        this.dataColumnsShadow = [...this.dataColumns];
        this.columnsToDisplay = Object.keys(this.dataColumns[0]);
        this.dataSource = new MatTableDataSource(this.dataColumns);
        this.dataSourceLength = this.dataSource.length;
      })
  }


  ngOnInit() {
    this.sourceToTargetComparisonService.uploadStatus$.subscribe(id => {

      this.isSubmitSuccessful = true;
      if (id) {
        
        this.documentId = id;

        this.sourceToTargetComparisonService.getItem(id).valueChanges().subscribe(result => {
          console.log("!!!!!!!!!Result")
          console.log(result);
          
          if (result.outputFileLocationJSON) {
            this.sourceToTargetComparisonService.setURL(result.outputFileLocationJSON);

            this.getData()
          }
        })
      }
    })
  }


  ngAfterViewInit() {
    if (this.paginator && this.dataSource) {
      this.dataSource.paginator = this.paginator;
    }
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }
}