<mat-card class="w-3/5 m-auto flex justify-center shadow-xl rounded-xl p-5 mb-5" appearance="outlined">
    <mat-card-header class="self-center mb-10">
        <mat-card-title>
            <h1 class="text-2xl">Basic Info</h1>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <form [formGroup]="userProfileForm" novalidate class="h-fit w-full m-auto p-1">
            <div class="grid grid-cols-2 w-full m-auto justify-center">
                <div class="col">
                    <span> &nbsp; </span>
                    <mat-form-field class="w-4/5">
                        <mat-label>First Name</mat-label>
                        <input matInput placeholder="First name" formControlName="firstName" #firstName>
                        <mat-error *ngIf="userProfileForm.controls['firstName'].hasError('required')">
                            First name is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col">
                    <span> &nbsp; </span>
                    <mat-form-field class="w-4/5">
                        <mat-label>Last Name</mat-label>
                        <input matInput placeholder="Last name" formControlName="lastName" #lastName>
                        <mat-error *ngIf="userProfileForm.controls['lastName'].hasError('required')">
                            Last name is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col">
                    <span> &nbsp; </span>
                    <mat-form-field class="w-4/5">
                        <mat-label>Company</mat-label>
                        <input matInput placeholder="Company" formControlName="company" #company>
                    </mat-form-field>
                </div>
                <div class="col">
                    <span> &nbsp; </span>
                    <mat-form-field class="w-4/5">
                        <mat-label>Designation</mat-label>
                        <input matInput placeholder="Designation" formControlName="designation" #designation>
                    </mat-form-field>
                </div>

                <div class="col">
                    <span> &nbsp; </span>
                    <mat-form-field class="w-4/5">
                        <mat-label>City</mat-label>
                        <input matInput placeholder="City" formControlName="city" #city>
                        <mat-error *ngIf="userProfileForm.controls['city'].hasError('required')">
                            City is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <span> &nbsp; </span>
                    <mat-form-field class="w-4/5">
                        <mat-label>Country</mat-label>
                        <input matInput placeholder="Country" formControlName="country" #country>
                        <mat-error *ngIf="userProfileForm.controls['country'].hasError('required')">
                            Country is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <input type="submit" hidden [disabled]="!userProfileForm.valid || !userProfileForm.dirty"
                (click)="callUpdateProfile(firstName.value, lastName.value, company.value, designation.value, city.value, country.value)" />

                <div class="col">
                    <mat-card-actions class="justify-center">
                        <button mat-raised-button color="secondary" (click)="resetValues()">Reset</button>
                    </mat-card-actions>
                </div>

                <div class="col">
                    <mat-card-actions class="justify-center">
                        <button mat-raised-button color="primary" [disabled]="!userProfileForm.valid || !userProfileForm.dirty" (click)="
              callUpdateProfile(firstName.value, lastName.value, company.value, designation.value, city.value, country.value)
              ">Update</button>
                    </mat-card-actions>
                </div>
            </div>
        </form>
    </mat-card-content>
</mat-card>