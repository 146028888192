import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FieldValue, serverTimestamp, Timestamp } from '@angular/fire/firestore';
import { SnackbarService } from '@app/shared/services/snackbar.service';
import { EventsToDbService } from '@app/shared/services/events-to-db.service';
import { Router } from "@angular/router";
import { DataPreviewService } from '@app/shared/services/data-preview.service';

export interface UserHistory {
  email: string;
  timestamp: Timestamp | FieldValue;
  lastModifiedUTC: string;
  fileName: string;
  inputFileLocation: string;
  outputFileLocationJSON: string;
  outputFileLocationPDF: string;
  lastModified: Timestamp | FieldValue;
}


@Component({
  selector: 'app-workbench',
  templateUrl: './workbench.component.html',
  styleUrls: ['./workbench.component.css']
})
export class WorkbenchComponent {
  
  file: File | null = null;

  filePath: string;

  uploadPercent: Observable<number> | null;
  uploadProgressValue: number;
  downloadURL: Observable<string> | null;
  private userHistory: AngularFirestoreCollection<UserHistory>;
  items: Observable<UserHistory[]>;
  notesAccordionOpen: Boolean = true;
  fileAccordionOpen: Boolean = true;
  @ViewChild('fileInput') fileInputVariable: ElementRef;

  constructor(
    private storage: AngularFireStorage,
    private afs: AngularFirestore,
    public auth: AngularFireAuth,
    private snackbarService: SnackbarService,
    public eventsToDbService: EventsToDbService,
    private router: Router,
    // private dataPreviewComponent: DataPreviewComponent
    private dataPreviewService: DataPreviewService

  ) {
    this.userHistory = afs.collection<UserHistory>('UserHistory');
    this.items = this.userHistory.valueChanges();
    this.file = null;
    this.filePath = null;
    this.uploadPercent = null;
    this.uploadProgressValue = 0;
    this.downloadURL = null;
    this.dataPreviewService.setDataFile(this.file)
  }

  onFilechange(event: any) {
    console.log(event.target.files[0])
    this.file = event.target.files[0]
    this.dataPreviewService.setDataFile(this.file)
  }

  constructFilePath(email, name) {
    return email + '__' + name.split(".")[0] + '__' + Date.now() + '.' + name.split(".").at(-1)
  }

  validateFile(file): boolean {
    // If there is no file
    if (!this.file) {
      this.snackbarService.openSnackBar(this.snackbarService.messages.fileNotSelected, null, "error");
      return false;
    }

    // File size cannot be greater than 500 KB
    if (file.size > 500000) {
      this.snackbarService.openSnackBar(this.snackbarService.messages.fileSizeExceeded, null, "error");
      return false;
    }

    // File type should be csv or Excel
    if (file.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      && file.type != "text/csv"
      && file.type != "application/vnd.ms-excel") {
      this.snackbarService.openSnackBar(this.snackbarService.messages.fileTypeInvalid, null, "error");
      return false;
    }
    return true;
  }

  async upload() {
    this.eventsToDbService.addEventToDB("clicked_upload_button");

    if (this.validateFile(this.file) == false) {
      this.fileInputVariable.nativeElement.value = "";
      this.file = null;
      this.dataPreviewService.setDataFile(this.file)
      return null;
    }

    this.uploadPercent = null;
    this.downloadURL = null;

    this.auth.user.subscribe((user) => {
      this.filePath = this.constructFilePath(user.email, this.file.name)

      let fileRef = this.storage.ref(this.filePath)
      let task = this.storage.upload(this.filePath, this.file)

      this.uploadPercent = task.percentageChanges();

      this.uploadPercent.subscribe((val) => { this.uploadProgressValue = val })


      task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL()

          this.fileInputVariable.nativeElement.value = "";
          this.file = null;
          this.dataPreviewService.setDataFile(this.file)

          let serverTs = serverTimestamp();

          this.downloadURL.subscribe((downloadURL) => {
            this.auth.user.subscribe((user) => {
              let item: UserHistory = {
                email: user.email,
                timestamp: serverTs,
                fileName: this.filePath,
                inputFileLocation: downloadURL,
                outputFileLocationJSON: "",
                outputFileLocationPDF: "",
                lastModified: serverTs,
                lastModifiedUTC: new Date().toUTCString()
              }
              this.addItem(item);
            })
          })
        })
      )
        .subscribe()
    })
  }

  addItem(item: UserHistory) {
    // this.userHistory.add(item);
    let docName = this.filePath.split('__')[0] + '__' + this.filePath.split('__')[1] + '__' + this.filePath.split('__')[2].split('.')[0]
    this.userHistory.doc(docName).set(item)
    this.eventsToDbService.addEventToDB("uploaded_file");
    this.filePath = null;
  }

  goToReportsList() {
    this.eventsToDbService.addEventToDB("navigated_to_reports_list_page");
    this.router.navigate(['reports-list']);
  }

}