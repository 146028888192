<!-- <p *ngIf="isSubmitSuccessful">
  <button class="mt-2" mat-raised-button color="primary" (click)="getData()">Check for results</button>
</p> -->



<div *ngIf="!dataSource && isSubmitSuccessful">
  {{this.placeholderText}}

  
<ngx-skeleton-loader *ngIf="!dataSource" [theme]="{ animation: 'progress',
height: '200px'
}"></ngx-skeleton-loader>
</div>


<div *ngIf="dataSource" class="flex flex-col w-full h-96 p-2">
  <div class="max-w-full h-96 overflow-auto ">
    <table mat-table [dataSource]="dataSource" class="w-full p-2">
      <ng-container *ngFor="let colName of columnsToDisplay;" [matColumnDef]="colName">
        <th mat-header-cell *matHeaderCellDef>{{colName}}</th>
        <td mat-cell *matCellDef="let row">
          {{row[colName]}}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
    </table>
  </div>
  <mat-paginator #paginator [length]="dataSourceLength" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
    aria-label="Select page">
  </mat-paginator>

  <!-- <div class="align-middle">
        <button mat-raised-button (click)="onReset($event)" class="w-5">
          Reset
        </button>
      </div> -->


</div>