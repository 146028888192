<div class="w-full mt-5 mb-5 m-auto bg-indigo-300 scale-105">
    <div class="bg-teal-100 border-t-4 rounded-b text-teal-900 px-4 py-3 shadow-md">
        <div class="flex justify-center m-auto">

            <div>
                <p class="font-bold">Plan: Limited-time free trial</p>
                <p class="text-sm">You're currently on the limited-time free trial plan of Anode.</p>
            </div>
        </div>
    </div>
</div>