import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {Router} from "@angular/router";
import { SnackbarService } from '../shared/services/snackbar.service';
import { FormValidationAssistanceService } from '@app/shared/services/form-validation-assistance.service';

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent {

  welcomeMessage: string;
  copyrightMessage: string;
  showPassword: boolean;
  errorMessageEmail: string;
  errorMessagePassword: string;
  
  constructor(
    public authenticationService: AuthenticationService, 
    private fb: FormBuilder,
    public auth: AngularFireAuth, 
    private router: Router, 
    private snackbarService:SnackbarService,
    private formValidationAssistanceService: FormValidationAssistanceService
  ) {
    this.welcomeMessage = "Welcome to Anode!";
    this.copyrightMessage = "2023 Codygon";
    this.showPassword = false;
  }

  loginForm = this.fb.group({
    userEmail: [null, [Validators.required, Validators.email]],
    userPassword: [
      null, 
      [
        Validators.required,
        Validators.minLength(this.formValidationAssistanceService.getPasswordMinLength()),
        Validators.pattern(this.formValidationAssistanceService.getPasswordRegexString())
      ]
    ],
  });

  navigateToSignup() {
    this.router.navigate(['signup']);
  }

  navigateToForgotPassword () {
    this.router.navigate(['forgot-password']);
  }

  callLogin(email, password) {
    this.authenticationService.login(email, password)
  }

  callLoginWithGoogle() {
    this.authenticationService.loginWithGoogle();
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  ngOnInit() {
    this.errorMessageEmail = this.formValidationAssistanceService.getErrorMessage("email")
    this.errorMessagePassword = this.formValidationAssistanceService.getErrorMessage("password")
  }


}