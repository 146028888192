<div class="w-full p-5 m-2">

    <div class="mt-4">
        Have questions about how to use the tool correctly or want to share feedback?
    </div>

    <div class="mt-4">
        <a href="mailto:support@codygon.com"><button (click)="sendMailToSupport()" mat-button class="bg-amber-300 font-medium">Write to us!</button></a>
    </div>

</div>