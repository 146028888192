<p *ngIf="!dataSource">Add a file to preview its data here</p>

<mat-accordion *ngIf="dataSource">
  <mat-expansion-panel [expanded]="panelOpenState">
    <mat-expansion-panel-header class="bg-[#3f51b5]">
      <mat-panel-title class="text-white font-medium">
        Preview Data
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div *ngIf="dataSource" class="flex flex-col w-full h-96 p-2">
      <div class="max-w-full h-96 overflow-auto ">
        <table mat-table [dataSource]="dataSource" class="w-full p-2">
          <ng-container *ngFor="let colName of columnsToDisplay;" [matColumnDef]="colName">
            <th mat-header-cell *matHeaderCellDef>{{colName}}</th>
            <td mat-cell *matCellDef="let row">
              {{row[colName]}}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
        </table>
      </div>
      <mat-paginator #paginator [length]="dataSourceLength" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
        aria-label="Select page">
      </mat-paginator>

      <!-- <div class="align-middle">
        <button mat-raised-button (click)="onReset($event)" class="w-5">
          Reset
        </button>
      </div> -->


    </div>
  </mat-expansion-panel>
</mat-accordion>