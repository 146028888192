<div class="h-[100vh] min-h-[100vh] w-[100vw] landing-space">
  <div class="grid grid-cols-5 max-h-screen pb-5 h-full">

    <div class="flex flex-col rounded-xl col-span-3 m-auto max-h-screen text-white">
      <div class="flex justify-center m-auto scale-90 -translate-y-5">
        <figure class="flex m-auto justify-center w-[50%]">
          <img src="https://storage.googleapis.com/anode-beta-assets/CodygonLogoSocialMedia_400x400.png"  alt="" />
        </figure>
      </div>
      <login-note></login-note>
    </div>

    
    <div class="col-span-2 p-2 m-auto w-full h-full">
        <login-form></login-form>
    </div>

  </div>
</div>