import { Component, inject } from '@angular/core';
import { EventsToDbService } from '@app/shared/services/events-to-db.service';

@Component({
  selector: 'app-cta-support',
  templateUrl: './cta-support.component.html',
  styleUrls: ['./cta-support.component.css']
})
export class CtaSupportComponent {

  constructor (
    public eventsToDbService: EventsToDbService
  ) {}

  sendMailToSupport() {
    this.eventsToDbService.addEventToDB("clicked_cta_button_support");
  }
}
