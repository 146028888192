import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import {Router} from "@angular/router";


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent {

  constructor(
    public authenticationService: AuthenticationService, 
    private fb: FormBuilder, 
    public auth: AngularFireAuth, 
    private router: Router
  ) {}


  forgotPasswordForm = this.fb.group({
    email: [null, [Validators.required, Validators.email]]
  });

  onSubmit(): void {
    // alert('Thanks!');
  }

  callResetPassword(email) {
    this.authenticationService.proceedWithPasswordReset(email)
  }

  ngOnInit() {
    this.auth.currentUser.then(
      (result: any) => {
        if (result) { this.router.navigate(['login']) }
      }
    )
  }

}


