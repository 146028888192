<div class="w-full m-auto mt-10">
  <mat-card class="shadow-xl rounded-xl mb-10 pb-5" appearance="outlined">
    <mat-grid-list cols="2" rowHeight="8rem">
      <mat-grid-tile>
        <app-cta-support></app-cta-support>
      </mat-grid-tile>
      <div class="inline-block h-[9.2rem] m-auto w-0.5 self-stretch bg-slate-100">
      </div>
      <mat-grid-tile>
        <app-cta-sales></app-cta-sales>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card>
</div>