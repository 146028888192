import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormValidationAssistanceService {

  passwordMinLength:number = 6;
  passwordRegexString: string = "^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[@$!%*?&^#_\\-\\[\\](){}|<>~.])[A-Za-z\\d@$!%*?&^#_\\-\\[\\](){}|<>~.]+$";
  public errorMessages: any = {
    email: 'The email is not in the correct format',
    password: 'The password must be at least 6 characters long and must contain a letter, a number and a special character (@$!%*?&^#_-[](){}|<>~.)',
  }

  constructor() { 
  }

  getErrorMessage(eType) {
    if (eType == "email") { 
      return this.errorMessages.email
    } else if (eType == "password") {
      return this.errorMessages.password
    }
    return "Error"
  }

  getPasswordMinLength() {
    return this.passwordMinLength
  }

  getPasswordRegexString() {
    return this.passwordRegexString
  }
}
