import { Component, OnInit } from '@angular/core';
import { GetVisualizationDataService } from '../get-visualization-data.service';
import { ReportHealthComponent } from '@app/report-health/report-health.component';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { EventsToDbService } from '@app/shared/services/events-to-db.service';
import { Location } from '@angular/common'

@Component({
  selector: 'app-report-detailed',
  templateUrl: './report-detailed.component.html',
  styleUrls: ['./report-detailed.component.css']
})
export class ReportDetailedComponent implements OnInit {

  reportName: string;

  constructor(
    private getVisualizationDataService: GetVisualizationDataService, 
    private route: ActivatedRoute,
    private router: Router,
    public eventsToDbService: EventsToDbService,
    private location: Location
  ) {
    this.reportName = "";
  }

  ngOnInit() {
    let jsonURL = this.route.snapshot.paramMap.get('jsonURL');
    
    this.getVisualizationDataService.getResultsData(jsonURL).subscribe(data => {
      let reportName = data.datasetName.split("__")[1]
      reportName = decodeURI(reportName)
      this.reportName = reportName;
    });

  }

  goToUpload() {
    this.eventsToDbService.addEventToDB("navigated_to_upload_page");
    this.router.navigate(['file-upload']);
  }

  goBack() {
    this.location.back();
  }


}
