import { Component, OnInit, ViewChild } from '@angular/core';
import { read, utils } from 'xlsx';
import { DataPreviewMultiService } from '@app/shared/services/data-preview-multi.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-data-preview-source',
  templateUrl: './data-preview-source.component.html',
  styleUrls: ['./data-preview-source.component.css']
})
export class DataPreviewSourceComponent implements OnInit {
  reportName: string;
  columnsToDisplay: any[];
  anomalyColumns: any[];
  dataColumns: any[];
  dataColumnsShadow: any[];
  dataColumnsFiltered: any[];
  sliderValueStart: number;
  sliderValueEnd: number;
  dataSource: any = null;
  dataSourceLength: any;
  threshold: number;
  multivariateHelpText: string;
  arrayBuffer: any;
  panelOpenState: Boolean = true;
  private datBS = new BehaviorSubject<any>(null);

  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }

  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  constructor(
    private dataPreviewMultiService: DataPreviewMultiService,
  ) {
    this.reportName = "";
    this.columnsToDisplay = [];
    this.anomalyColumns = [];
    this.dataColumns = [];
    this.dataColumnsShadow = [];
    this.dataColumnsFiltered = [];
    this.dataPreviewMultiService.setDataColumnsSource(this.dataSource)
  }

  onReset(event) {
    this.dataColumns = null;
    this.dataColumns = JSON.parse(JSON.stringify(this.dataColumnsShadow));
    this.dataSource.data = this.dataColumns;
    this.dataPreviewMultiService.setDataColumnsSource(this.dataSource)
  }

  getExcelData() {
    return this.datBS.asObservable();
  }

  readExcelFile(file) {
    let dat = []
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      dat = utils.sheet_to_json(worksheet, { raw: true })
      console.log(utils.sheet_to_json(worksheet, { raw: true }));
      this.dataPreviewMultiService.setDataFileSource(dat)
      this.datBS.next(dat)
    }
    fileReader.readAsArrayBuffer(file);

    return dat
  }

  ngOnInit() {
    this.dataPreviewMultiService.getDataFileSource().subscribe(data => {
      if (!data) return
      this.getExcelData().subscribe(dat => {
        if (!dat) { dat = []; return }
        this.dataColumns = dat
        this.dataColumnsShadow = [...this.dataColumns];
        this.columnsToDisplay = Object.keys(this.dataColumns[0]);
        this.dataSource = new MatTableDataSource(this.dataColumns);
        this.dataSourceLength = this.dataSource.length;
      })
      if (data instanceof Blob) {
        this.readExcelFile(data)
      }
    })

    this.dataPreviewMultiService.getDataColumnsSource().subscribe(dat => {
      // if (!dat) return
      if (!dat) { dat = [] }
      this.dataColumns = dat
      if(this.dataColumns.length > 0) {
        this.columnsToDisplay = Object.keys(this.dataColumns[0]);
        this.dataSource = new MatTableDataSource(this.dataColumns);
        this.dataSourceLength = this.dataSource.length;
      }
    })
  }

  ngAfterViewInit() {
    if (this.paginator && this.dataSource) {
      this.dataSource.paginator = this.paginator;
    }
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }
}