<div class="w-full">
  <mat-card class="shadow-md rounded-xl p-5 -mt-6" appearance="outlined">
    
  <mat-card-header class="justify-center flex font-bold m-auto pb-5">
    <mat-card-title class="text-2xl p-4">
        Section B
    </mat-card-title>
    <mat-card-subtitle>
        This section provides information about anomalies detected on individual columns (first 50 rows only), categorized by classes.
    </mat-card-subtitle>
  </mat-card-header>
  <ngx-skeleton-loader *ngIf="!dataUnivariate" [theme]="{ animation: 'progress',
    height: '200px'
  }"></ngx-skeleton-loader>
    <mat-tab-group mat-stretch-tabs="true" (selectedTabChange)="onTabChange($event)">
      <mat-tab *ngFor="let column of dataUnivariate;" [label]="column.columnName">
        <ng-template matTabContent>
          <mat-grid-list cols="2" class="bg-slate-50" rowHeight="32rem">
            <mat-grid-tile>
              <div class="grid grid-cols-1">
                <div>
                  <ngx-charts-bar-horizontal-stacked [view]="view" [scheme]="colorScheme"
                    [results]="column.anomalyClassesPerColumn" [gradient]="gradient" [xAxis]="showXAxis"
                    [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
                    [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                    (select)="onSelect($event, column.columnName)">
                  </ngx-charts-bar-horizontal-stacked>
                </div>
                <div class="flex justify-end">
                  <button mat-flat-button (click)="onReset($event)" class="m-2 bg-cyan-200">Reset Filters</button>
                </div>
              </div>

            </mat-grid-tile>

            <mat-grid-tile>
              <div class="flex flex-col w-full h-96 p-2 -mt-4">
                <div class="max-w-full h-96 overflow-auto">
                  <table mat-table [dataSource]="column.rows" matSort class="w-full">
                    <ng-container matColumnDef="value">
                      <th mat-header-cell *matHeaderCellDef> Value </th>
                      <td mat-cell *matCellDef="let row"> {{row.value}} </td>
                    </ng-container>

                    <ng-container matColumnDef="dataType">
                      <th mat-header-cell *matHeaderCellDef> Data Type </th>
                      <td mat-cell *matCellDef="let row"> {{row.dataType}} </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="colsUnivariate; sticky:true"></tr>
                    <tr mat-row *matRowDef="let row; columns: colsUnivariate"></tr>
                  </table>
                </div>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </ng-template>

      </mat-tab>
    </mat-tab-group>

    <div class="flex justify-center m-auto mt-6">
      <img src="../assets/anomaly-classes.png" alt="">
    </div>
  </mat-card>

</div>