import { Component } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent {

  userProvider: string;

  constructor(
    public authenticationService: AuthenticationService,
  ) { }

  ngOnInit() {
    this.authenticationService.afAuth.user.subscribe(
      (user: any) => {
        if (user) {
          if (user.emailVerified) {
            this.userProvider = user.providerData[0].providerId
          }
        }
      })
  }
}
