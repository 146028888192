<div class="w-full p-5 m-2">

    <div class="mt-4">
        Need more data quality and anomaly detection checks for your enterprise data?
    </div>

    <div class="mt-4">
        <a href="mailto:sales@codygon.com"><button (click)="sendMailToSales()" mat-button class="bg-amber-300 font-medium">Contact us!</button></a>
    </div>

</div>