import { Component, OnInit } from '@angular/core';
import { NgxChartsModule, Color, ScaleType } from '@swimlane/ngx-charts';
import { GetVisualizationDataService } from '../get-visualization-data.service';
import { ReportHealthComponent } from '@app/report-health/report-health.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-report-univariate',
  templateUrl: './report-univariate.component.html',
  styleUrls: ['./report-univariate.component.css']
})
export class ReportUnivariateComponent implements OnInit {

  reportName: string;
  colsUnivariate: string[];

  dataUnivariate: any[];
  dataUnivariateShadow: any[];

  view: [number, number];

  // options
  showXAxis: boolean;
  showYAxis: boolean;
  gradient: boolean;
  showLegend: boolean;
  showXAxisLabel: boolean;
  xAxisLabel: string;
  showYAxisLabel: boolean;
  yAxisLabel: string;

  colorScheme: string | Color;

  classMapping: any;


  constructor(private getVisualizationDataService: GetVisualizationDataService, private route: ActivatedRoute) {

    this.reportName = "";
    this.colsUnivariate = ['value', 'dataType'];
    this.view = [700, 400];

    this.showXAxis = true;
    this.showYAxis = true;
    this.gradient = false;
    this.showLegend = false;
    this.showXAxisLabel = true;
    this.xAxisLabel = 'Valid vs Invalid';
    this.showYAxisLabel = true;
    this.yAxisLabel = 'Classes';

    this.colorScheme = {
      domain: ['#51B53F', '#B53F51'],
      group: ScaleType.Ordinal,
      selectable: true,
      name: 'ValidInvalid',
    };

    this.classMapping = {
      "Class 0": "class0",
      "Class 1": "class1",
      "Class 2": "class2",
      "Class 3": "class3",
      "Class 4": "class4",
      "Class 5": "class5",
      "Class 6": "class6"
    };
  }

  filterInvalidData(row) {  return row.anomalyClasses[this["series"]] }

  filterValidData(row) {  return !row.anomalyClasses[this["series"]]  }

  copyShadowTableIntoActual() {
    for (let i = 0; i < this.dataUnivariate.length; i++) {
      this.dataUnivariate[i].rows = this.dataUnivariateShadow[i].rows
    }
  }

  onReset(event) {
    this.copyShadowTableIntoActual();
  }

  onSelect(event, columnName) {
    event.series = this.classMapping[event.series];
    this.copyShadowTableIntoActual();
    this.dataUnivariate.forEach(column => {
      if (column.columnName == columnName) {
        if (event.label == "Invalid") { column.rows = column.rows.filter(this.filterInvalidData, event) }
        if (event.label == "Valid") { column.rows = column.rows.filter(this.filterValidData, event) }
      }
    });
  }

  onTabChange(event) {
    setTimeout(() => this.copyShadowTableIntoActual(), 400);
    // this.dataUnivariate = [...this.dataUnivariateShadow]
  }

  ngOnInit() {
    let jsonURL = this.route.snapshot.paramMap.get('jsonURL');
    
    this.getVisualizationDataService.getResultsData(jsonURL).subscribe(data => {
          this.reportName = data.datasetName;
          this.dataUnivariate = data.univariate;
          this.dataUnivariateShadow = JSON.parse(JSON.stringify(this.dataUnivariate))
    });
    
  }

}

