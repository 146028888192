<div class="grid grid-cols-1 content-center w-[99vw] justify-items-center">

  <div *ngIf="auth.user | async as user;" class="text-2xl mt-2 mb-4">
    <h1>Welcome, {{ user.displayName }}!</h1>
  </div>


  <div class="bg-blue-50 w-7/12 rounded-2xl drop-shadow-xl p-5 m-2">

    <app-file-upload-instructions></app-file-upload-instructions>

    <div class="h-fit">

      <form action="/profile" method="post" enctype="multipart/form-data" class="p-5 ml-4 mb-2">
        <input [disabled]="this.uploadProgressValue > 0 && !this.downloadURL" (change)="this.onFilechange($event)"
          class="form-control" type="file" id="formFile"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          #fileInput>
      </form>

      <button [disabled]="(this.uploadProgressValue > 0 && !this.downloadURL) || !this.file" (click)="upload()"
        type="button" mat-flat-button color=primary class="mb-2">
        <mat-icon aria-hidden="false" aria-label="Go to upload file" fontIcon="upload"></mat-icon>
        Upload
      </button>


      <div *ngIf="this.uploadProgressValue > 0 && !this.downloadURL"
        class="mt-5 w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
        <div class="bg-blue-600 h-2.5 rounded-full" [style]="{width: uploadProgressValue + '%'}"></div>
        <div>{{ uploadProgressValue | number: '1.2-2' }} %</div>
      </div>

      <div class="m-auto mt-5 align-middle p-1" *ngIf="downloadURL | async">
        <div class="flex justify-center align-middle m-auto p-1">
          <mat-icon aria-hidden="false" aria-label="Go to upload file" fontIcon="check_circle"></mat-icon>
          <span class="ml-1">Upload Complete!</span>
        </div>
        Please check the <span><a (click)="goToReportsList()" class="underline">Reports</a></span> section after some time to see the status
        of the report
      </div>
    </div>
  </div>


</div>

<div class="w-[99vw] content-center justify-items-center">
  <div class="w-[58vw] m-auto">
    <app-cta-contact></app-cta-contact>
  </div>
</div>