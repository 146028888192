import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  
  public loading = false;

  constructor() { }

  
  public setLoading(val : boolean) {
    this.loading = val;
  }
  
}
