import { Component, OnInit, ViewChild } from '@angular/core';
import { DataPreviewService } from '@app/shared/services/data-preview.service';
import { BehaviorSubject, first } from 'rxjs';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {
  reportName: string;
  columnsToDisplay: any[];
  anomalyColumns: any[];
  dataColumns: any[];
  dataColumnType: any[];
  dataColumnsShadow: any[];
  dataColumnsFiltered: any[];
  dataSource: any = null;
  dataSourceLength: any;
  arrayBuffer: any;
  dataProfile: any = [];
  sqlInput: any = "";
  private datBS = new BehaviorSubject<any>(null);

  constructor(
    private dataPreviewService: DataPreviewService,
  ) {
    this.columnsToDisplay = [];
    this.dataColumns = [];
    this.dataColumnsShadow = [];
    this.dataProfile = [];
  }

  zipArraysIntoObjects(values, types) {
    // Ensure both arrays are of the same length
    if (values.length !== types.length) {
      throw new Error('Arrays must be of the same length');
    }
  
    // Zip the values and types into an array of objects
    const result = values.map((value, index) => ({ value, type: types[index] }));
    return result;
  }

  getObjectKeys(obj: object): string[] {
    return Object.keys(obj);
  }

  getTypesForRow(row) {
    return row.map(element => typeof element);
  }

  getType(obj) {
    return Object.prototype.toString.call(obj).slice(8, -1);
  }

  coerceToDate(value) {
    if (value instanceof Date) {
        return value; // Already a Date object
    }

    if (typeof value === 'string') {
        const timestamp = Date.parse(value);
        if (!isNaN(timestamp)) {
            return new Date(timestamp);
        }
    }

    return value
    throw new Error('Invalid input for date coercion');
}

  getTypesForFirstRow(array: any[]): string[] {
    if (array.length === 0 || typeof array[1] !== 'object' || array[1] === null) {
      return [];
    }
  
    let firstObjectInArray = array[1];
    firstObjectInArray = Object.keys(firstObjectInArray).map(key => this.getType(firstObjectInArray[key]))
    return firstObjectInArray;
  }

  updateSQLInput(value) {
    this.sqlInput = value
  }

  setRules(): void {
    this.getFilteredRecords()
  }

  getSQL() {
    this.getFilteredRecords()
  }

  getFilteredRecords() {
  }

  resetFiltering() {
    
  }

  isIterable(obj: any): boolean {
    return typeof obj === 'object' && obj !== null && Symbol.iterator in obj;
  }

  ngOnInit() {
    this.dataPreviewService.getDataFile().subscribe(data => {
      if (!data) return
      this.dataColumns = data
      if (!this.isIterable(this.dataColumns)) { return }
      this.dataColumnsShadow = [...this.dataColumns];
      this.columnsToDisplay = Object.keys(this.dataColumns[0]);
      this.dataColumnType = this.getTypesForFirstRow(this.dataColumns);
      this.dataProfile = this.zipArraysIntoObjects(this.columnsToDisplay, this.dataColumnType);
      // this.dataSourceLength = this.dataSource.length;
    })
  }
}