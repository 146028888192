import { Component } from '@angular/core';
import {MatExpansionModule} from '@angular/material/expansion';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent {
  panelOpenState = false;

  tncHTML = '<a href="https://bit.ly/45LsKTf" target="_blank" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Terms & Conditions</a>';
  privacyPolicyHTML = '<a href="https://bit.ly/3CwfYLb" target="_blank" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Privacy Policy</a>';

  faqs = [
    {
      q: "What is Anode?",
      a: "Anode is a data quality copilot powered by ML algorithms that find anomalous values in your data, enhanced by GPT models from Microsoft Azure and OpenAI. This web app is a showcase of Anode's capabilities. To understand more about what Anode can do, please feel free to contact us. Anode is a product of Codygon."
    },
    {
      q: "How do I read the report that is generated?",
      a: `The report is divided into two sections - Section A and Section B.
      The first part of the report uses ML algorithms to assign an Anode score per row.
      The second section of the report shows anomalies per column based on the classes of errors we've defined.`
    },
    {
      q: "Do you own the data I upload to the site?",
      a: `No. The ownership of the content remains unchanged. You retain any intellectual property rights you have in your content. Please feel free to take a look at the ${this.tncHTML} and ${this.privacyPolicyHTML} pages.`
    },
    {
      q: "Anode is different than traditional anomaly detection and data quality tools...",
      a: `Yes. The idea for Anode is to be a modern data quality copilot that not only assists data stewards but also end-users and consumers of data. We believe that maintaining data quality is not just the responsibility of one person or one team but should be a collective effort. Keeping out the complexity by having an easy-to-use tool that doesn't require too much manual effort is the way to encourage everyone to participate.`
    },
    {
      q: "What about database connectivity? Excel and csv uploads are only part of my pipeline...",
      a: `We have options for database connectivity in the enterprise version. You can contact us for more details.`
    },
    {
      q: "I want anomalies detected on time-series data in depth.",
      a: `That is possible. There is a lot of value in analysis of trends over time. We would love to connect to understand how our solution can help with your specific requirements.`
    },
    {
      q: "The file size limit is so low! I want to analyze lots of data.",
      a: `We've kept some limits for this web app as this is a showcase of what Anode can do. However, it is possible for Anode to analyze large amounts of data, be it ingesting data from a data lake, lakehouse, database or a data warehouse. Please get in touch with us!`
    },
    {
      q: "What is Workbench?",
      a: `Workbench is for users who want a rules-based approach to analyzing data. It allows users to create slices of their data by writing rules without the report-generation capability.`
    },
    {
      q: "I want more data quality checks. Also I want feature X...",
      a: `Sure! Please contact us and we'll be happy to connect with you to understand your requirements better.`
    }
  ]
}
