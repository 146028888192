<div class="w-[99vw] p-10">
    <mat-card class="shadow-xl rounded-xl p-5 -mt-10" appearance="outlined">
        <mat-card-header class="text-2xl mb-6 -mt-2 font-bold flex justify-center">
            <mat-card-title>
                FAQs
            </mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <div class="flex justify-center m-auto ">
                <mat-accordion class="w-3/4 m-auto text-left">
                    <mat-expansion-panel *ngFor="let faq of faqs;">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="font-bold">
                                {{faq.q}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <div [innerHTML]="faq.a"></div>
                        </ng-template>
                    </mat-expansion-panel>
                </mat-accordion>

            </div>
        </mat-card-content>
    </mat-card>

    <app-cta-contact></app-cta-contact>
</div>