<div class="grid grid-cols-1 content-center w-[99vw] justify-items-center">
  <div *ngIf="auth.user | async as user;" class="text-2xl mt-2 mb-4">
    <h1>Workbench:</h1>
    <h3>Rule-based analysis</h3>
  </div>

  <div class="bg-blue-50 w-10/12 rounded-2xl drop-shadow-xl p-5 m-2">
    <mat-accordion>
      <mat-expansion-panel [expanded]="notesAccordionOpen">
        <mat-expansion-panel-header class="bg-[#2a15e6]">
          <mat-panel-title class="text-white font-medium">
            Notes
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <app-workbench-instructions></app-workbench-instructions>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="mt-5 mb-5">
      &nbsp;
    </div>


    <div class="h-fit">

      <mat-accordion>
        <mat-expansion-panel [expanded]="fileAccordionOpen">
          <mat-expansion-panel-header class="bg-[#2a15e6]">
            <mat-panel-title class="text-white font-medium">
              Select File
            </mat-panel-title>
          </mat-expansion-panel-header>

          <form action="/profile" method="post" enctype="multipart/form-data" class="p-5 ml-4 mb-2">
            <input [disabled]="this.uploadProgressValue > 0 && !this.downloadURL" (change)="this.onFilechange($event)"
              class="form-control" type="file" id="formFile"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              #fileInput>
          </form>

        </mat-expansion-panel>
      </mat-accordion>


      <div class="mt-5 mb-5">
        &nbsp;
      </div>

      <app-data-preview></app-data-preview>
      
      <div class="mt-5 mb-5">
        &nbsp;
      </div>
      <app-configure-before-upload *ngIf="this.file"></app-configure-before-upload>

      
    </div>

  </div>


</div>

<div class="w-[99vw] content-center justify-items-center">
  <div class="w-[58vw] m-auto">
    <app-cta-contact></app-cta-contact>
  </div>
</div>