import { Component, OnInit, ViewChild, inject} from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Timestamp } from '@angular/fire/firestore';
import { Observable, map, timestamp } from 'rxjs';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { getLocaleDateFormat } from '@angular/common';
import { EventsToDbService } from '@app/shared/services/events-to-db.service';

export interface UserHistory {
  email: string;
  timestamp: Timestamp;
  inputFileLocation: string;
  outputFileLocationPDF: string;
  outputFileLocationJSON: string;
}

@Component({
  selector: 'app-reports-list',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.css']
})
export class ReportsListComponent {

  private userHistory: AngularFirestoreCollection<UserHistory>;

  // items: Observable<UserHistory[]>;
  items: any;

  columnsToDisplay = ['timestamp', 'fileName', 'fileType', 'inputFileLocation', 'outputFileLocationPDF', 'outputFileLocationJSON'];

  // @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator, {static: false})
  set paginator(value: MatPaginator) {
    if (this.items){
      this.items.paginator = value;
    }
  }
  
  @ViewChild(MatSort, {static: false})
  set sort(value: MatSort) {
    if (this.items){
      this.items.sort = value;
    }
  }

  defaultSort: MatSortable = {
    id: 'timestamp',
    start: 'desc',
    disableClear: true
  };

  reportsListNotes: string[];

  /**
   * Reports going as far back as this figure (milliseconds) are available for the user to see. 
   * Any reports generated prior to this won't be visible to the user.
   */
  reportsListDuration: number;

  /**
   * Beyond this time, there might not be a chance that the report will generate
   */
  reportGenerationWaitCutoff: number;

  reports: number;

  // dateObj: any;

  constructor(
    private afs: AngularFirestore, 
    private router: Router, 
    public auth: AngularFireAuth,
    public eventsToDbService: EventsToDbService
  ) {

    // this.dateObj = new Date();

    this.reportsListNotes = [
      `Only data for the past 7 days will be shown.`,
      `If you've just uploaded a file, it may take a few minutes for the report to generate.`,
      `Once generated, an icon will be shown under the corresponding column indicating that the report is available.`,
      `You don't need to wait on this page for the report to generate.`];

    this.reportsListDuration = 604800000;
    this.reportGenerationWaitCutoff = 14400000;
    
    this.auth.user.subscribe((user) => {
      this.userHistory = afs.collection<UserHistory>('UserHistory', ref => ref.where(
        'email', '==', user.email).where('timestamp', ">=", new Date(Date.now() - this.reportsListDuration)).orderBy('timestamp', 'desc'));
      // this.items = this.userHistory.valueChanges();
      this.userHistory.valueChanges().subscribe(result => {
        this.items = new MatTableDataSource(result);
        this.items.sort = this.sort;
        this.items.paginator = this.paginator;
      });
    })
  }

  addItem(item: UserHistory) {
    this.userHistory.add(item);
  }

  captureFileDownloadEvent() {
    this.eventsToDbService.addEventToDB("clicked_file_download_for_an_entry");
  }

  capturePDFReportEvent() {
    this.eventsToDbService.addEventToDB("clicked_PDF_download_for_an_entry");
  }

  loadReport(jsonURL) {
    this.eventsToDbService.addEventToDB("clicked_report_view_for_an_entry");
    this.router.navigate(['/report-detailed', jsonURL]);
  }

  goToReport() {
    this.eventsToDbService.addEventToDB("navigated_to_report_page");
    this.router.navigate(['/report-detailed', './assets/data_unified.json']);
  }

  isRequestRecent(timestamp) {
    /**
     * If the request was made in the last 4 hours or not
     */
    return new Date(timestamp.seconds * 1000) >= new Date(Date.now() - this.reportGenerationWaitCutoff);
  }

  ngAfterViewInit() {
    if (this.items) {
      this.sort.sort({ id: null, start: 'desc', disableClear: false });
      this.sort.sort(this.defaultSort);
      (this.sort.sortables.get('timestamp') as MatSortHeader)._setAnimationTransitionState({ toState: 'active' });
      this.items.sort = this.sort;
      this.items.paginator = this.paginator;
    }
  }
}
