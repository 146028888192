<div class="m-5 p-2 bg-slate-100 grid grid-cols-5">

    <div class="col-span-1">
        <ngx-charts-pie-chart [view]="view" [scheme]="colorScheme" [results]="reportHealthData" [gradient]="gradient"
            [legend]="showLegend" [labels]="showLabels" [doughnut]="isDoughnut" [arcWidth]="arcWidth">
        </ngx-charts-pie-chart>
    </div>

    <div *ngIf="reportHealthData" class="font-bold p-4 col-span-1 flex flex-col justify-center items-center">
        <div class="text-base">Your data looks</div>
        <div class="text-2xl">{{healthPercent}}%</div>
        <div class="text-base">healthy</div>
        <div>
            <span class="center relative inline-block select-none whitespace-nowrap rounded-lg py-2 px-2.5 align-baseline text-xs leading-none tracking-wide" style="background-color: {{[this.dataQualityBGColor]}}; color: {{[this.dataQualityTextColor]}}">
                {{this.dataQualityText}}
            </span>
        </div>
    </div>

    <div class="p-4  col-span-3 text-left m-auto">
        <div>
            The <mat-chip matTooltip="Each row is assigned an Anode score between 0 and 1 (higher = more anomalous), based on how different it appears from the rest of the dataset">Anode score</mat-chip> below is based on our AI-powered algorithm for anomaly detection.
            <br />The % health on the left is calculated by taking the ratio of rows that look correct and the rows that
            have anomalies.
            <br />

            <span *ngIf="this.reportHealthData">
                Rows having Anode score above {{this.threshold}} are marked as more anomalous than
                others.
                <br />
                Based on this criteria, you have <mat-chip matTooltip="Records having Anode score 0.7 or more"><span class="font-semibold text-[#B53F51]">{{this.reportHealthData[1].value}}</span> anomalous
                row(s)</mat-chip> and <mat-chip matTooltip="Records having Anode score less than 0.7"><span class="font-semibold text-[#51B53F]">{{this.reportHealthData[0].value}}</span> correct
                row(s)</mat-chip>.
                <br/>
                There is also a <mat-chip matTooltip="AI-powered comments that describe more about the anomaly">Comments</mat-chip> column which provides additional information about the Anomalies.
            </span>
            <br />
        </div>
    </div>
</div>