import { Component } from '@angular/core';

@Component({
  selector: 'app-file-upload-instructions',
  templateUrl: './file-upload-instructions.component.html',
  styleUrls: ['./file-upload-instructions.component.css']
})
export class FileUploadInstructionsComponent {

}
