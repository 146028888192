<div class="w-screen p-10">
    <mat-card class="shadow-xl rounded-xl p-5 -mt-10" appearance="outlined">

        <mat-card-header class="text-2xl mb-6 -mt-2 font-bold flex justify-center">
            <mat-card-title>
                You're just one step away!
            </mat-card-title>
            
        </mat-card-header>


        <mat-card-content>

            <div>
                Please check your inbox and click on the link in the email you've received to verify your email.
            </div>

            
            <div>
                <button mat-button class="bg-orange-500 text-white" (click)="this.authenticationService.sendVerificationMail()">Send verification email again</button>
            </div>
        </mat-card-content>


    </mat-card>

</div>