<div
  class="h-screen w-screen flex place-content-center justify-center align-middle bg-[url('assets/boliviainteligente-uiJNYtAbfVU-unsplash_1920x1200.jpg')]">

  <div class="drop-shadow-2xl border rounded-xl h-full w-3/5 ">

    <form [formGroup]="signupForm" novalidate class="h-full flex w-full">

      <mat-card
        class="auth-card p-2 justify-center align-middle content-center place-content-center flex w-full bg-white">
        <mat-card-header class="self-center mb-10">
          <mat-card-title>
            <h1 class="text-xl">Sign Up</h1>
          </mat-card-title>
        </mat-card-header>


        <mat-card-content>
          <div class="grid grid-cols-2 ml-10 mr-10">
            <div class="col">
              <span> * </span>
              <mat-form-field class="w-4/5">
                <input matInput placeholder="First name" formControlName="firstName" #firstName>
                <mat-error *ngIf="signupForm.controls['firstName'].hasError('required')">
                  First name is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <span> * </span>
              <mat-form-field class="w-4/5">
                <input matInput placeholder="Last name" formControlName="lastName" #lastName>
                <mat-error *ngIf="signupForm.controls['lastName'].hasError('required')">
                  Last name is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col">
              <span> &nbsp; </span>
              <mat-form-field class="w-4/5">
                <input matInput placeholder="Company" formControlName="company" #company>
              </mat-form-field>
            </div>
            <div class="col">
              <span> &nbsp; </span>
              <mat-form-field class="w-4/5">
                <input matInput placeholder="Designation" formControlName="designation" #designation>
              </mat-form-field>
            </div>

            <div class="col">
              <span> * </span>
              <mat-form-field class="w-4/5">
                <input matInput placeholder="City" formControlName="city" #city>
                <mat-error *ngIf="signupForm.controls['city'].hasError('required')">
                  City is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <span> * </span>
              <mat-form-field class="w-4/5">
                <input matInput placeholder="Country" formControlName="country" #country>
                <mat-error *ngIf="signupForm.controls['country'].hasError('required')">
                  Country is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col">
              <span> * </span>
              <mat-form-field class="w-4/5">
                <input matInput placeholder="Email" formControlName="email" #email>
                <mat-error *ngIf="signupForm.controls['email'].hasError('required')">Email is <strong>required</strong>
                </mat-error>
                <mat-error>{{this.errorMessageEmail}}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <span> * </span>
              <mat-form-field class="w-4/5">
                <input matInput placeholder="Password" formControlName="password" #password [type]="showPassword ? 'text' : 'password'">
                <mat-icon matSuffix (click)="togglePasswordVisibility()">
                  {{showPassword?'visibility_off':'visibility'}}
              </mat-icon>
                <mat-error *ngIf="signupForm.controls['password'].hasError('required')">Password is <strong>required</strong>
                </mat-error>
                <mat-error>{{this.errorMessagePassword}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

        </mat-card-content>

        <input type="submit" hidden [disabled]="!signupForm.valid"
          (click)="callSignup(email.value, password.value, firstName.value, lastName.value, company.value, designation.value, city.value, country.value)" />

        <div class="grid grid-cols-2 w-3/5 self-center mt-28">
          <div class="col-span-1">
            <mat-card-actions>
              <button mat-raised-button color="secondary" routerLink="/login">Back</button>
            </mat-card-actions>
          </div>
          <div class="col-span-1">
            <mat-card-actions align="end" class="">
              <button mat-raised-button color="primary" [disabled]="!signupForm.valid" (click)="
              callSignup(email.value, password.value, firstName.value, lastName.value, company.value, designation.value, city.value, country.value)
              ">Sign up</button>
            </mat-card-actions>
          </div>
        </div>
      </mat-card>
    </form>
  </div>
</div>