<div *ngIf=!isSmallScreen>
  <app-navbar *ngIf="auth.user | async as user;"></app-navbar>
  <div class="container text-center text-lg">
    <ngx-loading [show]="loaderService.loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
    <router-outlet></router-outlet>
  </div>
</div>
<div *ngIf=isSmallScreen>
  <div class="flex h-screen flex-col bg-black text-white">
    <div class="flex flex-col m-auto text-center p-10 h-full">
      <div class="flex flex-col m-auto">
        <div style="font-size: 3em">
          😅
        </div>
        <div class="m-auto">
          <h1 class="font-black text-2xl mb-2">Hello there!</h1>
          <h2>
            We would love for you to use Anode.
            <br>
            However, our app is currently only optimized for the web.
            <br>
            Please try logging in using your desktop, laptop or tablet device.
            <br>
            If you're already on a tablet device, please try landscape mode.
          </h2>
        </div>
      </div>

    </div>
    <figure class="flex m-auto w-1/4 mb-10">
      <img src="https://storage.googleapis.com/anode-beta-assets/CodygonLogoSocialMedia_400x400.png" alt="" />
    </figure>
  </div>
</div>