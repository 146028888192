import { Injectable, inject } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { FieldValue, serverTimestamp, Timestamp } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Analytics, logEvent } from '@angular/fire/analytics';

export interface UserEvent {
  email: string;
  timestamp: Timestamp | FieldValue;
  eventString: string;
}

@Injectable({
  providedIn: 'root'
})
export class EventsToDbService {

  private analytics: Analytics = inject(Analytics);

  private userEvents: AngularFirestoreCollection<UserEvent>;
  eventsList: Observable<UserEvent[]>;

  constructor(
    private afs: AngularFirestore,
    public auth: AngularFireAuth
  ) {
    this.userEvents = afs.collection<UserEvent>('UserEvents');
    this.eventsList = this.userEvents.valueChanges();
  }


  addEventToDB(eventString) {
    // Add event to Google Analytics
    logEvent(this.analytics, eventString);

    // Add event to Firebase
    this.auth.currentUser.then((user) => {
      let userEvent: UserEvent = {
        email: user.email,
        timestamp: serverTimestamp(),
        eventString: eventString
      }
      this.addEvent(userEvent);
    })
  }

  addEvent(userEvent: UserEvent) {
    let docName = userEvent.email + '__' + Date.now();
    this.userEvents.doc(docName).set(userEvent);
  }
}
