import { Component } from '@angular/core';

@Component({
  selector: 'app-legal-links',
  templateUrl: './legal-links.component.html',
  styleUrls: ['./legal-links.component.css']
})
export class LegalLinksComponent {

}
