import { Component } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';


@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent {

  constructor(
    public authenticationService: AuthenticationService,
    private router: Router
  ) {
    
  }

  ngOnInit() {
    
    this.authenticationService.afAuth.user.subscribe(
      (user: any) => {
        if (!user || user.emailVerified) { 
            this.router.navigate(['/login'])
        }
      }
    )
  }

}


