import { Component } from '@angular/core';

@Component({
  selector: 'app-trial-notice',
  templateUrl: './trial-notice.component.html',
  styleUrls: ['./trial-notice.component.css']
})
export class TrialNoticeComponent {

}
