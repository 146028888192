import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { EventsToDbService } from '@app/shared/services/events-to-db.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import {Router} from "@angular/router";
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

export interface UserProfile {
  firstName: string;
  lastName: string;
  company: string;
  designation: string;
  city: string,
  country: string,
  email: string;
  password: string;
}

@Component({
  selector: 'app-user-profile-settings',
  templateUrl: './user-profile-settings.component.html',
  styleUrls: ['./user-profile-settings.component.css']
})
export class UserProfileSettingsComponent {
  private UserProfile: AngularFirestoreCollection<UserProfile>;

  userRetrieved: boolean;

  constructor(
    public authenticationService: AuthenticationService, 
    public eventsToDbService: EventsToDbService,
    private fb: FormBuilder, 
    public auth: AngularFireAuth, 
    private router: Router, 
    private afs: AngularFirestore) {

    this.UserProfile = afs.collection<UserProfile>('UserProfile');
    this.userRetrieved = false;
  }

  userProfileForm = this.fb.group({
    firstName: ["", Validators.required],
    lastName: ["", Validators.required],
    company: [""],
    designation: [""],
    city: ["", Validators.required],
    country: ["", Validators.required]
  });

  callUpdateProfile(firstName, lastName, company, designation, city, country) {
    this.eventsToDbService.addEventToDB("updated_user_profile");
    this.authenticationService.updateUserProfile(firstName, lastName, company, designation, city, country);
  }

  resetValues() {
    this.auth.currentUser.then(
      (user: any) => {
        if (user) { 
          
          let userProfileFirebase = this.UserProfile.doc(user.email).get();
          userProfileFirebase.subscribe(doc => {
              if (doc.exists) {
                  console.log("Document data:", doc.data());
                  console.log(this.userProfileForm)
                  this.userProfileForm.controls['firstName'].setValue(doc.data().firstName);
                  this.userProfileForm.controls['lastName'].setValue(doc.data().lastName);
                  this.userProfileForm.controls['company'].setValue(doc.data().company);
                  this.userProfileForm.controls['designation'].setValue(doc.data().designation);
                  this.userProfileForm.controls['city'].setValue(doc.data().city);
                  this.userProfileForm.controls['country'].setValue(doc.data().country);
                  this.userRetrieved = true;
              } else {
                  // doc.data() will be undefined in this case
                  console.log("No such document!");
              }
          })
        }
      }
    )
  }

  ngOnInit() {
    this.resetValues();
  }
}