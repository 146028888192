<div
  class="h-screen w-screen flex place-content-center justify-center align-middle bg-[url('assets/boliviainteligente-uiJNYtAbfVU-unsplash_1920x1200.jpg')]">

  <div class="drop-shadow-2xl border rounded-xl h-full w-3/5 ">

    <form [formGroup]="forgotPasswordForm" novalidate class="h-full flex w-full">

      <mat-card
        class="auth-card p-2 justify-center align-middle content-center place-content-center flex w-full bg-white">
        <mat-card-header class="self-center mb-10">
          <mat-card-title>
            <h1 class="text-xl">Reset Password</h1>
          </mat-card-title>
        </mat-card-header>


        <mat-card-content>
          <div class="grid grid-cols-1 ml-10 mr-10">
            
            <div class="col">
              <span> * </span>
              <mat-form-field class="w-4/5">
                <input matInput placeholder="Email" formControlName="email" #email>
                <mat-error *ngIf="forgotPasswordForm.controls['email'].hasError('required')">
                  Email is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

        </mat-card-content>

        <input type="submit" hidden [disabled]="!forgotPasswordForm.valid"
          (click)="callResetPassword(email.value)" />

        <div class="grid grid-cols-2 w-3/5 self-center">
          <div class="col-span-1">
            <mat-card-actions>
              <button mat-raised-button color="secondary" routerLink="/login">Back</button>
            </mat-card-actions>
          </div>
          <div class="col-span-1">
            <mat-card-actions align="end" class="">
              <button mat-raised-button color="primary" [disabled]="!forgotPasswordForm.valid" (click)="
              callResetPassword(email.value)
              ">Send reset password link</button>
            </mat-card-actions>
          </div>
        </div>






      </mat-card>


    </form>


  </div>
</div>