import { Component } from '@angular/core';

@Component({
  selector: 'login-note',
  templateUrl: './login-note.component.html',
  styleUrls: ['./login-note.component.css']
})
export class LoginNoteComponent {

}
