import { Component } from '@angular/core';

@Component({
  selector: 'app-workbench-instructions',
  templateUrl: './workbench-instructions.component.html',
  styleUrls: ['./workbench-instructions.component.css']
})
export class WorkbenchInstructionsComponent {

}
