<mat-accordion>
    <mat-expansion-panel [expanded]="panelOpenState">
        <mat-expansion-panel-header class="bg-[#3f51b5]">
            <mat-panel-title class="text-white font-medium">
                Configure Rules
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
            <app-configuration></app-configuration>
        </div>
    </mat-expansion-panel>
</mat-accordion>