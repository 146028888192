<div class="w-screen flex justify-start p-2 -mt-4 mb-2 ml-5">
  <button mat-button (click)="goBack()" class="bg-slate-100 rounded-lg">Go back</button>
</div>

<div class="w-[99vw] p-10">
  <mat-card class="shadow-xl rounded-xl p-5 -mt-10" appearance="outlined">
    <mat-card-header class="text-2xl mb-6 -mt-2 font-bold flex justify-center">
      <mat-card-title>
        {{reportName}}
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>

      <mat-card class="shadow-md rounded-xl p-2 mb-20" appearance="outlined">
        <mat-card-header class="justify-center flex font-bold m-auto pb-5">
          <mat-card-title class="text-2xl p-4">
            Section A
          </mat-card-title>
          <mat-card-subtitle>
            This section provides information about anomalies detected on a per-row level (upto the first 50 rows), assigning an Anode Score to each row.
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>

          <app-report-health></app-report-health>

          <app-report-multivariate></app-report-multivariate>
        </mat-card-content>
      </mat-card>

      <app-report-univariate></app-report-univariate>

    </mat-card-content>

  </mat-card>

  <div class="w-full m-auto mt-10">
    <app-cta-contact></app-cta-contact>
  </div>
</div>