import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from "@angular/router";

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  constructor(
    public authenticationService: AuthenticationService,
    public auth: AngularFireAuth,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.auth.user.subscribe(
      (user: any) => {
        if (user) {
          if (!user.emailVerified) {
            this.router.navigate(['verify-email'])
          } else {
            this.router.navigate(['file-upload'])
          }
        }
      }
    )
  }
}