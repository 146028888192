import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetVisualizationDataService {

  defaultURL: string;

  constructor(private http: HttpClient) {
    this.defaultURL = "./assets/data_unified.json"
  }

  public getResultsData(dataURL): Observable<any> {
    if (!dataURL) {
      dataURL = this.defaultURL
    }
    return this.http.get(dataURL);
  }

  public getReportHealthData(): Observable<any> {
    return this.http.get("./assets/data_summary.json");
  }

}
