<mat-toolbar color=primary class="mb-6 h-16 sticky top-0 z-[9999]">
  <a routerLink="/file-upload" class="w-[100px] ml-2 mr-2 text-right">
    <img src="./assets/anode-logo.png" class="scale-110 -mt-2" alt="Anode Logo" *ngIf="userVerified">
    <span class="text-xs absolute top-10 left-28">Beta</span>
  </a>
  
  <button mat-flat-button (click)="goToUpload()" class="m-2 ml-6 bg-[#ff9131] text-white" *ngIf="userVerified">
    Upload
  </button>
  <button mat-flat-button (click)="goToWorkbench()" class="m-2 ml-2 bg-[#ff9131] text-white" *ngIf="userVerified">
    Workbench (Beta)
  </button>
  <button mat-flat-button (click)="goToSourceToTargetComparison()" class="m-2 bg-[#9131FF] text-white" *ngIf="userVerified">
    Source-to-Target
  </button>
  <button mat-flat-button (click)="goToReport()" class="m-2 bg-[#9131FF] text-white" *ngIf="userVerified">
    Sample Report
  </button>


  <div class="spacer"></div>

  <a (click)="goToAbout()" class="m-4" *ngIf="userVerified">About</a>
  <a (click)="goToHelp()" class="m-4" *ngIf="userVerified">Help</a>
  <a (click)="goToFAQs()" class="m-4" *ngIf="userVerified">FAQs</a>
  <a (click)="goToReportsList()" class="m-4" *ngIf="userVerified">Reports</a>
  <a (click)="goToProfile()" class="m-2"><img src="./assets/user-profile.png" alt="" *ngIf="userVerified"></a>
  
  <button (click)="logout()" mat-flat-button class="m-2">Logout</button>
</mat-toolbar>