import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ReportsListComponent } from './reports-list/reports-list.component';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared/helpers/auth.guard';
import { ReportDetailedComponent } from './report-detailed/report-detailed.component';
import { ReportMultivariateComponent } from './report-multivariate/report-multivariate.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserHelpComponent } from './user-help/user-help.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { AboutComponent } from './about/about.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { WorkbenchComponent } from './workbench/workbench.component';
import { FaqComponent } from './faq/faq.component';
import { SourceToTargetComponent } from './source-to-target/source-to-target.component';

const routes: Routes = [
  {
    path: 'application', component: AppComponent, canActivate: [AuthGuard]
  },
  {
    path: 'file-upload', component: FileUploadComponent, canActivate: [AuthGuard]
  },
  {
    path: 'workbench', component: WorkbenchComponent, canActivate: [AuthGuard]
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'signup', component: SignupComponent
  },
  {
    path: 'source-to-target', component: SourceToTargetComponent, canActivate: [AuthGuard]
  },
  {
    path: 'reports-list', component: ReportsListComponent, canActivate: [AuthGuard]
  },
  {
    path: 'user-profile', component: UserProfileComponent, canActivate: [AuthGuard]
  },
  {
    path: 'about', component: AboutComponent, canActivate: [AuthGuard]
  },
  {
    path: 'user-help', component: UserHelpComponent, canActivate: [AuthGuard]
  },
  {
    path: 'faqs', component: FaqComponent, canActivate: [AuthGuard]
  },
  {
    path: 'report-detailed/:jsonURL', component: ReportDetailedComponent, canActivate: [AuthGuard]
  },
  {
    path: 'report-multivariate', component: ReportMultivariateComponent, canActivate: [AuthGuard]
  },
  {
    path: 'verify-email', component: VerifyEmailComponent,
  },
  {
    path: 'forgot-password', component: ForgotPasswordComponent
  },
  {
    path: 'configuration', component: ConfigurationComponent
  },
  {
    path: '', component: LoginComponent, canActivate: [AuthGuard]
  },
  {
    path: '**', redirectTo: '/login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
