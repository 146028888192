<div class="w-[99vw] p-10">
    <mat-card class="shadow-xl rounded-xl p-5 -mt-10" appearance="outlined">

        <mat-card-header class="text-2xl mb-6 -mt-2 font-bold flex justify-center">
            <mat-card-title>
                Reports
            </mat-card-title>
            <mat-card-subtitle>
                <div class="text-sm italic mb-2 mt-2">
                    <li *ngFor="let note of this.reportsListNotes;" class="list-none">
                        {{note}} <br/>
                    </li>
                </div>
            </mat-card-subtitle>            
        </mat-card-header>

        <mat-card-content>

            <table mat-table [dataSource]="items" class="p-10 ml-2" matSort>
                <ng-container matColumnDef="timestamp">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Timestamp </th>
                    <td mat-cell *matCellDef="let item"> {{item.timestamp.seconds * 1000 | date:'yyyy/MM/dd h:mm:ss a'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="fileName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> File Name </th>

                    <td mat-cell *matCellDef="let item"> {{item.fileName.split('__')[1]}} </td>
                </ng-container>
                <ng-container matColumnDef="fileType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> File Type </th>

                    <td mat-cell *matCellDef="let item"> {{item.fileName.split('__')[2].split(".")[1]}} </td>
                </ng-container>
                <ng-container matColumnDef="inputFileLocation">
                    <th mat-header-cell *matHeaderCellDef> Uploaded File </th>
                    <td mat-cell *matCellDef="let item">
                        <a 
                            *ngIf="item.inputFileLocation" 
                            target="_blank" 
                            href="{{item.inputFileLocation}}"
                        >
                            <button 
                                (click)="captureFileDownloadEvent()"
                            >
                                <mat-icon
                                    aria-hidden="false"
                                    aria-label="Uploaded file"
                                    fontIcon="download"
                                    matTooltip="Download File"
                                    class="text-slate-800"
                                ></mat-icon>
                            </button>
                        </a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="outputFileLocationPDF">
                    <th mat-header-cell *matHeaderCellDef> Report (PDF) </th>
                    <td mat-cell *matCellDef="let item">
                        <a 
                            *ngIf="item.outputFileLocationPDF" 
                            target="_blank"
                            href="{{item.outputFileLocationPDF}}"
                        >
                            <button 
                                (click)="capturePDFReportEvent()"
                            >
                                <mat-icon 
                                    aria-hidden="false" 
                                    aria-label="PDF Report"
                                    fontIcon="download"
                                    matTooltip="Download PDF"
                                    class="text-slate-800"
                                >
                                </mat-icon>
                            </button>
                        </a>
                        <mat-icon 
                            *ngIf="!item.outputFileLocationPDF && isRequestRecent(item.timestamp)" 
                            aria-hidden="false" 
                            aria-label="PDF Report"
                            fontIcon="pending" 
                            matTooltip="Pending"
                            class="text-yellow-400">
                        </mat-icon>
                        <mat-icon 
                            *ngIf="!item.outputFileLocationPDF && !isRequestRecent(item.timestamp)" 
                            aria-hidden="false" 
                            aria-label="PDF Report"
                            fontIcon="error" 
                            matTooltip="Error"
                            class="text-red-500">
                        </mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="outputFileLocationJSON">
                    <th mat-header-cell *matHeaderCellDef> Report (Interactive) </th>
                    <td mat-cell *matCellDef="let item">
                        <button 
                            *ngIf="item.outputFileLocationJSON"
                            (click)="loadReport(item.outputFileLocationJSON)"
                        >
                            <mat-icon 
                                aria-hidden="false"
                                aria-label="Interactive Report" 
                                fontIcon="assessment"
                                matTooltip="Go to Report"
                                class="text-slate-800"
                            ></mat-icon>
                        </button>
                        <mat-icon
                            *ngIf="!item.outputFileLocationJSON && isRequestRecent(item.timestamp)"
                            aria-hidden="false"
                            aria-label="Interactive Report" 
                            fontIcon="pending"
                            matTooltip="Pending"
                            class="text-yellow-400"
                        ></mat-icon>
                        <mat-icon
                            *ngIf="!item.outputFileLocationJSON && !isRequestRecent(item.timestamp)"
                            aria-hidden="false"
                            aria-label="Interactive Report" 
                            fontIcon="error"
                            matTooltip="Error"
                            class="text-red-500"
                        ></mat-icon>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>


            </table>
            <mat-paginator *ngIf="items" #paginator [length]="items.length" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
                aria-label="Select page">
            </mat-paginator>

        </mat-card-content>


    </mat-card>
    <app-cta-contact></app-cta-contact>

</div>