import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    public auth: AngularFireAuth,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Observable<boolean>(obs => {
      this.auth.user.subscribe(
      (user) => {
        console.log(user)
        if (user) {
          /**
           * Proceed only if verified
           */
          if(user.emailVerified) {
            obs.next(true);
          }
          else {
            this.router.navigate(['/verify-email'], { queryParams: { returnUrl: state.url }});
            obs.next(false);
          }
        } else {
          // not logged in so redirect to login page
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
          obs.next(false);
        }
      })
    })

  }

}
