<div class="grid grid-cols-5 shadow-lg bg-white rounded-lg leading-loose gap-2 p-7">
    <div class="col-span-2 m-auto">
        <img src="assets/goodformatbadformat_landscape.png" alt="" class="bg-white rounded-lg">
    </div>
    <div class="col-span-3 m-auto">
        <h1 class="mb-4"><b>Points to note</b></h1>
        <ul class="list-disc text-left">
            <li class="m-2">
                File types: xlsx, xls and csv
            </li>
            <li class="m-2">
                File size: Upto 500 KB
            </li>
            <li class="m-2">
                Only the first sheet and first 50 rows will be processed
            </li>
            <li class="m-2">
                Remove rows and columns not part of the data
            </li>
            <li class="m-2">
                Generated entries will disappear after 7 days
            </li>
        </ul>
    </div>
</div>