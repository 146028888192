import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CustomRouteReuseStrategyService extends BaseRouteReuseStrategy {

  override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    let url = future && (<any>future).url;
    if (url) {
      if (url.length > 0) {
        url = url[0].path
      }
    }
    return super.shouldReuseRoute(future, curr) && !url.includes('report-detail');
  }
}
