<div class="w-full mt-20 mb-10">
  <div class="mb-6" *ngIf="dataSource">
    <div class="text-lg m-3 text-zinc-700 font-semibold">
      Anode Score
    </div>
    <span class="text-sm m-3 text-zinc-500">0</span>
    <mat-slider min="0" max="1" discrete="true" step="0.05" class="w-1/2">
      <input matSliderStartThumb [value]="sliderValueStart" [(ngModel)]="sliderValueStart"
        (valueChange)="onValChangeStart($event)">
      <input matSliderEndThumb [value]="sliderValueEnd" [(ngModel)]="sliderValueEnd"
        (valueChange)="onValChangeEnd($event)">
    </mat-slider>
    <span class="text-sm m-3 text-zinc-500">1</span>
  </div>
  <div class="italic text-base mt-2">
    {{this.multivariateHelpText}}
  </div>
  <div class="flex justify-end">
    <button mat-flat-button (click)="onReset($event)" class="mr-2 -mt-10 mb-10 bg-cyan-200">Reset Filters</button>
  </div>

  <div class="flex flex-col w-full p-2">
    <div class="max-w-full max-h-[30rem] overflow-auto ">
      <ngx-skeleton-loader *ngIf="!dataSource" [theme]="{ animation: 'progress',
            height: '200px'
          }"></ngx-skeleton-loader>
         
      <table mat-table [dataSource]="dataSource" class="w-full p-2">

        <ng-container *ngFor="let colName of columnsToDisplay;" [matColumnDef]="colName">
          <th mat-header-cell *matHeaderCellDef>{{colName}}</th>

          <td mat-cell *matCellDef="let row">
            {{row[colName]}}
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay" [ngClass]="{ 'bg-red-200': row['Anode Score'] >= this.threshold}"></tr>
        <tr *matNoDataRow>
          <td [attr.colspan]="columnsToDisplay.length">
            <span class="italic font-medium">
              There are no records that match the filter criteria
            </span>
          </td>
        </tr>

      </table>
    </div>

    <mat-paginator #paginator [length]="dataSourceLength" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]"
      aria-label="Select page">
    </mat-paginator>
  </div>
</div>