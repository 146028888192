<div class="grid grid-cols-1 content-center w-[99vw] justify-items-center">
    <div *ngIf="auth.user | async as user;" class="text-2xl mt-2 mb-4">
        <h1>Source-to-Target Comparison:</h1>
        <h3>Compare data to source and identify anomalies</h3>
    </div>

    <div class="bg-blue-50 w-10/12 rounded-2xl drop-shadow-xl p-5 m-2">
        <mat-accordion>
            <mat-expansion-panel [expanded]="notesAccordionOpen">
                <mat-expansion-panel-header class="bg-[#2a15e6]">
                    <mat-panel-title class="text-white font-medium">
                        Notes
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <app-workbench-instructions></app-workbench-instructions>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <div class="mt-5 mb-5">
            &nbsp;
        </div>


        <div class="h-fit">

            <mat-accordion>
                <mat-expansion-panel [expanded]="fileAccordionOpen">
                    <mat-expansion-panel-header class="bg-[#2a15e6]">
                        <mat-panel-title class="text-white font-medium">
                            Select Files
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="grid grid-cols-2 gap-2">
                        <div>
                            <h1 class="mt-2">A</h1>
                            <form action="/profile" method="post" enctype="multipart/form-data" class="p-5 ml-4 mb-2">
                                <input [disabled]="this.uploadProgressValue > 0 && !this.downloadURL"
                                    (change)="this.onSourceFilechange($event)" class="form-control" type="file"
                                    id="formFile"
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    #fileInput>
                            </form>

                            <app-data-preview-source></app-data-preview-source>
                        </div>
                        <div>
                            <h1 class="mt-2">B</h1>

                            <form action="/profile" method="post" enctype="multipart/form-data" class="p-5 ml-4 mb-2">
                                <input [disabled]="this.uploadProgressValue > 0 && !this.downloadURL"
                                    (change)="this.onTargetFilechange($event)" class="form-control" type="file"
                                    id="formFile"
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    #fileInput>
                            </form>

                            <app-data-preview-target></app-data-preview-target>

                        </div>
                    </div>

                    <button class="mt-4" [disabled]="(this.uploadProgressValue > 0 && !this.downloadURL) || (!this.sourceFile || !this.targetFile)" mat-raised-button (click)="uploadDataForComparison()" color="primary">
                        Upload
                    </button>

                </mat-expansion-panel>
            </mat-accordion>

            <div class="mt-5 mb-5" >
                <mat-accordion>
                    <mat-expansion-panel [expanded]="fileAccordionOpen">
                        <mat-expansion-panel-header class="bg-[#2a15e6]">
                            <mat-panel-title class="text-white font-medium">
                                Results
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <app-data-preview-source-to-target></app-data-preview-source-to-target>

                    </mat-expansion-panel>
                </mat-accordion>
            </div>

            <div class="mt-5 mb-5">
                &nbsp;
            </div>

        </div>

    </div>


</div>

<div class="w-[99vw] content-center justify-items-center">
    <div class="w-[58vw] m-auto">
        <app-cta-contact></app-cta-contact>
    </div>
</div>