<div class="flex flex-col h-[90vh] w-[99vw] justify-between">

    <div class="w-[99vw] p-10">
        <mat-card class="shadow-xl rounded-xl p-5 -mt-10" appearance="outlined">
            <mat-card-header class="text-2xl mb-6 -mt-2 font-bold flex justify-center">
                <mat-card-title>
                    Profile
                </mat-card-title>
            </mat-card-header>

            <mat-card-content>
                <div class="flex flex-col justify-center m-auto">

                    <app-user-profile-settings></app-user-profile-settings>

                    <app-user-password-settings *ngIf="userProvider == 'password'"></app-user-password-settings>

                    <app-trial-notice></app-trial-notice>


                    <app-legal-links></app-legal-links>
                </div>
            </mat-card-content>
        </mat-card>
        <app-cta-contact></app-cta-contact>
    </div>



</div>