<div class="text-xl anode-note rounded-2xl text-white p-10 m-auto ml-5">
  <div class="text-left">
    Dear Reader,
    <br />
    <br />
    Are you struggling with inaccurate and unreliable data?
    <br />
    We know how you feel, that's why we built Anode.
    <br />
    <br />
    Don't let poor data quality hinder your business performance any longer.
    <br />
    <br />
    Anode, an innovative AI-powered digital solution designed to revolutionize data quality management for
    businesses is here
    to help.
    <br />
    Feel free to log in and explore!
    <br />
    <br />
  </div>
  <div class="text-right">
    Yours sincerely, <br />
    Codygon
  </div>
</div>