import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { FieldValue, serverTimestamp, Timestamp } from '@angular/fire/firestore';
import { DataPreviewService } from '@app/shared/services/data-preview.service';
import { BehaviorSubject, first, Observable, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { EventsToDbService } from '@app/shared/services/events-to-db.service';
import { SnackbarService } from '@app/shared/services/snackbar.service';

interface RuleCondition {
  field: string;
  operator: string;
  value: string;
  customValue?: string;
  valueOrCustom: 'unique' | 'custom';
}

export interface UserRule {
  name: string;
  email: string;
  timestamp: Timestamp | FieldValue;
  conditions: RuleCondition[];
  logicalOperator: string;
  rule: string;
}

@Component({
  selector: 'app-rule-builder',
  templateUrl: './rule-builder.component.html',
  styleUrls: ['./rule-builder.component.css']
})
export class RuleBuilderComponent implements OnInit {
  conditions: RuleCondition[] = [];
  logicalOperator: string = 'AND';
  rule: string = '';
  ruleName: string = '';
  fields: string[] = [];
  operators: string[] = ['=', '!=', '>', '<'];
  reportName: string;
  columnsToDisplay: any[];
  anomalyColumns: any[];
  dataColumns: any[];
  dataColumnType: any[];
  dataColumnsShadow: any[];
  dataColumnsFiltered: any[];
  dataSource: any = null;
  dataSourceLength: any;
  arrayBuffer: any;
  dataProfile: any = [];
  sqlInput: any = "";
  private datBS = new BehaviorSubject<any>(null);
  firebaseCollectionName = 'UserRules'
  private userRules: AngularFirestoreCollection<UserRule>;
  rules: any;
  createRule: Boolean = false;
  selectedRule: string = null;
  ruleListColumns = ['name', 'rule'];
  ruleListLength: any;
  colMapping = {
    'name': 'Name',
    'rule': 'Rule'
  };

  constructor(
    public dialog: MatDialog,
    private dataPreviewService: DataPreviewService,
    private afs: AngularFirestore,
    public auth: AngularFireAuth,
    public eventsToDbService: EventsToDbService,
    private snackbarService: SnackbarService,
  ) {
    this.columnsToDisplay = [];
    this.dataColumns = [];
    this.dataColumnsShadow = [];
    this.dataProfile = [];

    this.auth.user.subscribe((user) => {
      this.userRules = afs.collection<UserRule>(this.firebaseCollectionName, ref => ref.where(
        'email', '==', user.email).orderBy('timestamp', 'desc'));
      this.userRules.valueChanges().subscribe(result => {
        this.rules = result
      });
    })
  }

  // Get unique values for a specific property of the objects in dataArray
  getUniqueValues(property: any): string[] {
    const uniqueValues: Set<string> = new Set();
    this.dataColumns.forEach(item => uniqueValues.add(item[property]));
    return Array.from(uniqueValues);
  }

  addCondition(): void {
    this.conditions.push({ field: '', operator: '', value: '', customValue: '', valueOrCustom: 'unique' });
  }

  removeCondition(index: number): void {
    this.conditions.splice(index, 1);
    this.buildRule();
  }

  buildRule(): void {
    const conditionString = this.conditions
      .map(condition => {
        if (condition.valueOrCustom === 'unique') {
          return `${condition.field} ${condition.operator} ${condition.value}`;
        } else {
          return `${condition.field} ${condition.operator} ${condition.customValue}`;
        }
      })
      .join(` ${this.logicalOperator} `);

    this.rule = conditionString.length > 0 ? `(${conditionString})` : '';
    this.getFilteredRecords(this.rule)
  }

  async saveRule() {

    // Check if the rule has a name before saving
    if (!this.rule) {
      this.snackbarService.openSnackBar(this.snackbarService.messages.ruleEmpty, null, "error");
    }
    // Check if the rule has a name before saving
    if (this.ruleName.trim() === '') {
      alert('Please provide a name for the rule before saving.');
      this.snackbarService.openSnackBar(this.snackbarService.messages.ruleNameNotProvided, null, "error");
      return;
    }

    try {
      await this.saveRulesToFirestore();
      console.log('Rules saved to Firestore.');
    } catch (error) {
      console.error('Error saving rules to Firestore:', error);
    }
  }

  zipArraysIntoObjects(values, types) {
    // Ensure both arrays are of the same length
    if (values.length !== types.length) {
      throw new Error('Arrays must be of the same length');
    }

    // Zip the values and types into an array of objects
    const result = values.map((value, index) => ({ value, type: types[index] }));
    return result;
  }

  getObjectKeys(obj: object): string[] {
    return Object.keys(obj);
  }

  getTypesForRow(row) {
    return row.map(element => typeof element);
  }

  getType(obj) {
    return Object.prototype.toString.call(obj).slice(8, -1);
  }

  coerceToDate(value) {
    if (value instanceof Date) {
      return value; // Already a Date object
    }
    if (typeof value === 'string') {
      const timestamp = Date.parse(value);
      if (!isNaN(timestamp)) {
        return new Date(timestamp);
      }
    }
    return value
  }

  trimFirstRow(array: any[]): string[] {
    if (array.length === 0 || typeof array[1] !== 'object' || array[1] === null) {
      return [];
    }

    let firstObjectInArray = array[1];
    firstObjectInArray = Object.keys(firstObjectInArray).map(key => firstObjectInArray[key].trim())
    return firstObjectInArray;
  }

  getTypesForFirstRow(array: any[]): string[] {
    if (array.length === 0 || typeof array[1] !== 'object' || array[1] === null) {
      return [];
    }

    let firstObjectInArray = array[1];
    firstObjectInArray = Object.keys(firstObjectInArray).map(key => this.getType(firstObjectInArray[key]))
    return firstObjectInArray;
  }

  updateSQLInput(value) {
    this.sqlInput = value
  }

  setRules(): void {
  }

  getSQL() {
  }

  filterData(pred) {
    return this.dataColumns.filter = pred
  }

  private parseFilterString(filterString: string): [string, string, string] {
    const conditionRegex = /(\w+(\s+\w+)?)\s*([=<>!]+)\s*([^)]+)/;
    const match = conditionRegex.exec(filterString);

    if (match) {
      // If the value is captured inside single quotes, use it; otherwise, use the regular match
      const value = match[4].trim();
      return [match[1], match[3], value];
    }

    return [null, null, null];
  }


  private evaluateFilterExpression(item: any): boolean {
    const conditions = this.rule.split(/\s+(AND|OR)\s+/);

    let result = true;
    let currentOperator = 'AND'; // Default logical operator

    conditions.forEach((condition, index) => {
      if (index % 2 === 0) {
        // Operand (condition)
        const [columnName, operator, value] = this.parseFilterString(condition);
        const trimmedColumnName = columnName.trim();
        const trimmedValue = value.trim();

        let conditionResult;

        switch (operator.toLowerCase()) {
          case '=':
            conditionResult = item[trimmedColumnName] == trimmedValue;
            break;
          case '>':
            conditionResult = item[trimmedColumnName] > trimmedValue;
            break;
          case '<':
            conditionResult = item[trimmedColumnName] < trimmedValue;
            break;
          case '!=':
            conditionResult = item[trimmedColumnName] != trimmedValue;
            break;
          default:
            conditionResult = false;
        }

        if (currentOperator === 'AND') {
          result = result && conditionResult;
        } else {
          result = result || conditionResult;
        }
      } else {
        // Logical operator
        currentOperator = condition.toUpperCase();
      }
    });

    return result;
  }




  applyFilter() {
    if (this.rule) {
      this.dataColumns = this.dataColumns.filter(item => this.evaluateFilterExpression(item));
      // this.dataColumns = this.evaluateFilterExpression(this.rule, this.dataColumns)
    } else {
      // Reset the filter and display all data
      this.dataColumns = this.dataColumns.slice();
    }
  }

  processRecordClick(row) {
    this.getFilteredRecords(row.rule)
  }

  getFilteredRecords(rule) {
    this.resetFiltering()
    this.rule = rule
    this.applyFilter()
    this.dataPreviewService.setDataColumns(this.dataColumns);
  }

  resetFiltering() {
    this.dataColumns = null;
    this.dataColumns = JSON.parse(JSON.stringify(this.dataColumnsShadow));
    this.dataPreviewService.setDataColumns(this.dataColumns);
  }

  isIterable(obj: any): boolean {
    return typeof obj === 'object' && obj !== null && Symbol.iterator in obj;
  }

  private async saveRulesToFirestore(): Promise<void> {
    this.eventsToDbService.addEventToDB("saved_rule");

    this.auth.user.subscribe((user) => {

      let serverTs = serverTimestamp();

      let documentData: UserRule = {
        name: this.ruleName,
        email: user.email,
        timestamp: serverTs,
        conditions: this.conditions,
        logicalOperator: this.logicalOperator,
        rule: this.rule,
      };

      // this.userRules.doc(user.email).set(documentData)
      // Add the document to the Firestore collection
      this.userRules.add(documentData);

      // Clear the form after saving
      this.clearForm();
    })
  }

  private clearForm(): void {
    this.conditions = [];
    this.logicalOperator = 'AND';
    this.rule = '';
    this.ruleName = '';
    this.getUserRules(); // Refresh the list of saved rules
  }

  private getUserRules(): void {
    this.auth.user.subscribe((user) => {
      this.userRules.valueChanges().subscribe(data => {
      });
    })
  }

  selectRule(rule: UserRule): void {
    this.conditions = rule.conditions;
    this.logicalOperator = rule.logicalOperator;
    this.rule = rule.rule;
    this.ruleName = rule.name;
  }

  renameObjKey(obj, old_key, new_key) {
    if (old_key !== new_key) {
      Object.defineProperty(obj, new_key,
        Object.getOwnPropertyDescriptor(obj, old_key));
      delete obj[old_key];
    }
  }

  ngOnInit() {
    this.dataPreviewService.getDataFile().subscribe(data => {
      if (!data) return
      if (!Array.isArray(data)) { return }
      this.dataColumns = data
      // for (let i of Object.keys(this.dataColumns[0]).map(key => key.trim())) {
      this.dataColumns.forEach(row => {
        Object.keys(row).forEach(element => {
          this.renameObjKey(row, element, element.trim())
        });
      })
      if (!this.isIterable(this.dataColumns)) { return }
      this.dataColumnsShadow = [...this.dataColumns];
      this.columnsToDisplay = Object.keys(this.dataColumns[0]);
      this.dataColumnType = this.getTypesForFirstRow(this.dataColumns);
      this.dataProfile = this.zipArraysIntoObjects(this.columnsToDisplay, this.dataColumnType);
      // this.dataSourceLength = this.dataSource.length;
      if (this.rules) {
        this.ruleListLength = this.rules.length;
      }
    })
    this.getUserRules();
  }
}